.innerCards{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    overflow-x: scroll;
    padding: 10px 5px 10px;
}

.innerCards::-webkit-scrollbar {
    height: 7px;
    border-radius: 10px;
}


.innerSubscriptionStatusBadges{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    align-items: stretch;
    gap: 15px;
    flex-wrap: wrap;
}
