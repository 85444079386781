.themeContent{
    .previewActionBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .3s;
        visibility: visible;
        opacity: 1;
    }

    .previewModeLabel {
        position: relative;
        white-space: nowrap;
        color: var(--catalina-blue);
        padding-left: 16px;
    }

    .previewModeLabel:before {
        content: '';
        position: absolute;
        left: 4px;
        top: 50%;
        margin-top: -2.5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transform-origin: center;
        background-color: var(--catalina-blue);
        animation: pulse 2s infinite;
    }
    .hide {
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0 !important;
    }
}
