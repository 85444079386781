.contentList{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.minHeightContentList{
    min-height: 300px;
}

.subscriptionSettingsBtn path:first-child{
    transform-origin: center;
    transition: transform 1s;
}

.subscriptionSettingsBtn:hover path:first-child{
    transform: rotate(360deg);
}

.innerProviders{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: fit-content;
    margin-left: auto;
    margin-bottom: 10px;
}

.buttonAccess{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    background-color: var(--swatch--cta-blue);
}

.loadingSyncCalendar{
    height: auto;
}

.selectedProvider{
    background-color: var(--catalina-blue);
    color: #FFFFFF;
    fill: #FFFFFF;
}

.selectedProvider > svg{
    fill: #FFFFFF;
}

/*BLOCK_SETTINGS*/
body:has(.userPages) .settingsBlock{
    max-width: 500px;
}
.buttonSaveDeliverySettings{
    margin-left: auto;
    margin-top: 10px;
    max-width: 200px;
    width: 100%;
}
/*BLOCK_SETTINGS*/

@media (max-width: 991px) {
    .contentList{
        margin-top: 20px;
    }
    .blockThemeCalendar .calendar:nth-child(3){
        display: none;
    }
    body:has(.userPages) .settingsBlock{
        max-width: 100%;
    }
    .buttonSaveDeliverySettings{
        width: 100%;
        max-width: 420px;
        margin-top: 25px;
        margin-right: auto;
    }
}

@media (max-width: 460px) {
    .innerProviders {
        width: 100%;
        max-width: initial;
    }
}
