.starRating{
    display: flex;
    align-items: center;
}
.buttonStar {
    font-size: 24px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: color .5s;
}
.buttonStar.on {
    color: var(--catalina-blue);
}
.buttonStar.off {
    color: #ccc;
}

.questionAnswer .buttonStar{
    font-size: 20px;
}
