.buttonUtmParams{
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 3px 15px;
    min-width: 140px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #e9ecef;
    cursor: default;
    max-width: fit-content;
    text-align: center;
}


.buttonUtmParams > span:first-letter{
    text-transform: uppercase;
}

.iconArrowUtmParams{
    margin: -5px 0;
    transition: all .5s;
}

.buttonUtmParams:hover > .iconArrowUtmParams{
    transform: rotate(180deg);
}

.innerUtmInfo{
    display: flex;
    margin-top: 15px;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.innerUtmInfo .buttonUtmParams{
    margin: 0;
}

.iconFilterUtmParams{
    margin-left: 10px;
    cursor: pointer;
}
