.groupPreview{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .3s;

    &:hover{
        background-color: #ece8e8;
    }
    &.active{
        background-color: #ece8e8;
    }
    &:last-child{
        margin-bottom: 1rem;
    }

    .name{
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    .memberCount{
        font-weight: 600;
        margin-left: auto;
        padding: 5px 7px;
        background-color: black;
        border-radius: 10px;
        color: #FFFFFF;
    }
}
