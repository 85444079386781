.innerGroup{
    flex: 1;
    width: 100%;
    border: 1px solid #e3dede;
    border-radius: 10px;
    height: calc(100vh - 110px);

    .groupName{
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
    }
}
