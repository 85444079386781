.innerGroupList{
    display: flex;
    flex-direction: column;
    border: 1px solid #e3dede;
    border-radius: 10px;
    max-width: 300px;
    width: 100%;
    height: calc(100vh - 110px);

    .title{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .groupList{
        flex: 1;
        margin-top: 20px;
        overflow-y: scroll;
    }
}
