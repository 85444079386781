.modalDialogCancelSubscription{
    max-width: 550px;
}
.modalContentCancelSubscription{
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: none;
}

.iconBin{
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #eae7dc;
    background-color: #eeedea;
    fill: var(--persian-red);
}

body:has(.userPages) .iconBin{
    border: 1px solid #eae7dc;
    background-color: #f9f0e4;
    fill: var(--swatch--cta-blue);
}

.inputReasonCancelSubscription textarea{
    min-height: 90px;
}
