.innerLoadingUserPage{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    backdrop-filter: blur(10px);
}

.userName{
    font-size: 30px;
    line-height: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.textNotes{
    white-space: pre-line;
}

.userNotesPopover{
    max-width: 50%;
    min-width: 20%;
    width: fit-content;
}

.titleBlock{
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 20px;
}


.userLocation{
    color: var(--dark-gray);
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--mediun-gray);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.coupon{
    font-size: 14px;
    line-height: 18px;
    padding: 3px 15px;
    background-color: #e9ecef;
    border-radius: 5px;
    max-width: 145px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

/*TABLE_USER_PROGRAMS*/
.innerTableUserThemes{
    margin-top: 24px;
}

.tableUserThemes :global(.buttonUtmParams){
    font-size: 15px;
    line-height: 18px;
    padding: 3px 15px;
}
/*TABLE_USER_PROGRAMS*/

/*TABLE_COUPONS*/
.statusIndicatore{
    display: block;
    min-width: 8px;
    width: 8px;
    min-height: 8px;
    height: 8px;
    border-radius: 50%;
}
.innerTableUserCoupons{
    flex: 0.7;
    min-width: 300px;
}
/*TABLE_COUPONS*/

.innerDropdownUserStreak{
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    gap: 15px;
}

.innerDropdownUserStreak :global(.dropHeader){
    flex: 1;
    background-color: #FFFFFF;
    max-width: 160px;
}
