.modalCreateTheme.rs-drawer{
    width: 500px;
}

.createThemeBody .innerPriceTypeRadioButtons .innerRadioButtons > .innerRadioButton{
    flex: 1;
    padding: 0;
    border-radius: var(--border-radius);
    border: 2px solid var(--border-input);
    transition: all .3s;

    .labelRadioButton{
        padding: 8px 10px;
        width: 100%;
        color: #a0a1a2;
    }
    .radioButton{
        display: none;
    }

    &:has(.radioButton:checked){
        border-color: var(--catalina-blue);
        .labelRadioButton{
            color: var(--catalina-blue);
        }
    }
}

.checkboxThemeContentType{
    margin-top: 4px;

    span{
        display: none;
    }
    .rs-checkbox-checker{
        padding: 0;
        min-height: auto;
        border-radius: var(--border-radius);
        border: 2px solid var(--border-input);
        transition: all .3s;

        label{
            position: relative;
            font-weight: 500;
            padding: 5px 14px;
            color: #a0a1a2;
            transition: all .3s;
        }

        &:has(input:checked){
            border-color: var(--catalina-blue);
            color: var(--catalina-blue);

            label{
                padding-left: 18px;
                color: var(--catalina-blue);

                &:before{
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    left: 7px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    background-color: var(--catalina-blue);
                    margin-right: 5px;
                }
            }
        }
    }
}
