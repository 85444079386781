body:has(.userPages) .subscriptionModal .rs-drawer-content{
    background-color: var(--swatch--warm-white)
}

.subscriptionModal .subscriptionImage{
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
}

.subscriptionModal .subscriptionProgramName{
    font-size: 26px;
    line-height: 30px;
}

.itemInfoSubscription{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #ced4da;
}

.itemInfoSubscription .label{
    font-size: 20px;
    line-height: 24px;
    color: #8d8f8f;
}

.itemInfoSubscription .value{
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.itemInfoSubscription .value > .secondSmallText{
    font-size: 16px;
    line-height: 16px;
    color: var(--swatch--cta-blue);
}

.renewSubscriptionBtn{
    max-width: 200px;
    width: 100%;
}

.threeDotsSubscription{
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
}

.timePickerSubscription{
    max-width: 120px;
}

body:has(.userPages) .timePickerSubscription > input.rc-time-picker-input{
    width: 100%;
    border-radius: 1rem;
    border: 1px solid #eae7dc;
    background-color: #f9f0e4;
}

.listTime.timeListPickerSubscription{
    z-index: 1050;
}

@media (max-width: 991px) {
    .subscriptionModal .subscriptionImage{
        width: 70px;
        height: 70px;
    }
    .subscriptionModal .subscriptionProgramName{
        font-size: 24px;
        line-height: 28px;
    }
    .itemInfoSubscription{
        padding: 15px 0;
    }
    .itemInfoSubscription .label{
        font-size: 16px;
        line-height: 20px;
    }
    .itemInfoSubscription .value{
        font-size: 16px;
        line-height: 20px;
    }
    .itemInfoSubscription .value > .secondSmallText{
        font-size: 14px;
        line-height: 14px;
    }
}

@media (max-width: 740px) {
    .subscriptionModal.rs-drawer-sm{
        width: 100%;
    }
}
