.innerAdminPages:has(.innerAdminGlobalChat){
    margin-left: -30px;
    padding-right: 0;
    padding-left: 0;
}

.innerAdminGlobalChat{
    display: flex;
    align-items: stretch;
    height: 100%;
}

.innerAdminGlobalChat .navigationAdmin{
    margin-right: 0;
}

.innerInfoSection:has(.innerAdminGlobalChat){
    padding: 0;
}

.innerUserChat{
    position: relative;
    flex: 1;
    min-width: 400px;
}

.bgChat{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}

.messagePlaceholder{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 20px;
    color: var(--catalina-blue);
    background-color: rgba(232, 229, 229, 0.9);
}

/*HEADER_CHAT*/
.chatHeaderSidebar{
    background-color: #FFFFFF;
    border-bottom: 2px solid rgb(232 233 239);
}

.innerPreviewChat{
    position: relative;
}

.chatHeaderSidebar .innerPreviewChat{
    cursor: default;
    border-bottom: none;
}

.chatHeaderSidebar .innerPreviewChat:hover{
    background-color: inherit;
}

.chatHeaderSidebar .userPreviewMessage{
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
}

.chatHeaderSidebar .infoPreviewChatLastMessage{
    margin-top: 3px;
    min-height: auto;
}
.blockedMessage{
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    color: var(--persian-red);
    background-color: #FFFFFF;

}
/*HEADER_CHAT*/

.innerChatTab{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 10px;
}

.globalChatForm{
    position: relative;
    margin: 5px 20px 0;
}

.globalChatForm .inputChat > textarea{
    font-size: 16px;
    line-height: 24px;
    background-color: #FFFFFF;
    min-height: 110px;
}

.globalChatForm .inputChat > textarea:disabled{
    background-color: #e9ecef;
}

.buttonSendMessage{
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 0;
    cursor: pointer;
    transition: transform .5s;
    background-color: transparent;
}

.buttonSendMessage:hover{
    transform: scale(1.05);
}

.innerChatList{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    height: 100%;
    padding-top: 13px;
    border-right: 2px solid rgb(232 233 239);
    overflow-x: scroll;
}

/*PREVIEW_MESSAGE*/
.innerPreviewChat{
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 8px 15px;
    border-bottom: 1px solid rgb(232 233 239);
    transition: background-color .5s;
    cursor: pointer;
}

.innerPreviewChat:hover{
    background-color: #f3f3f4;
}

.highlightedChat{
    background-color: #f3f3f4;
}

.userIcon.userPreviewMessage{
    position: relative;
    font-size: 24px;
    line-height: 26px;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
}

.selectModeChat .userPreviewMessage:after{
    content: '✓';
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    bottom: -5px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #0bc50b;
}

.userPreviewMessage .iconsInfo{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -7px;
    left: -7px;
}

.infoPreviewChat{
    display: inline-grid;
    width: 100%;
}

.innerPreviewChat.activeChat{
    background-color: #f3f3f4;
}

.infoPreviewChatTop{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.infoPreviewChatUserName{
    font-weight: 500;
    max-width: 100%;
    min-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000000;
}

.infoPreviewChatDateMessage{
    font-size: 12px;
    line-height: 16px;
    color: rgb(112, 117, 121);
}

.innerPreviewLastMessage{
    display: inline-grid;
    grid-template-columns: 1fr auto;
    align-items: flex-end;
    margin-top: 2px;
}

.infoPreviewChatLastMessage{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(112, 117, 121);
    min-height: 36px;
}

.infoPreviewChatNumberUnreadMessages{
    --size: 1.375rem;
    --padding: .4375rem;

    font-size: 13px;
    line-height: var(--size);
    font-weight: 500;
    max-width: fit-content;
    min-width: var(--size);
    height: var(--size);
    padding: 0 var(--padding);

    border-radius: calc(var(--size)/2);
    text-align: center;
    color: #FFFFFF;
    background-color: var(--catalina-blue);
}

/*PREVIEW_MESSAGE*/

/*FORM_SEARCH*/
.innerHeaderChatList{
    width: 100%;
    padding: 0 15px 23px;
    border-bottom: 2px solid rgb(232 233 239);
}

/*FORM_SEARCH*/

.innerNavigationHeader{
    overflow-x: scroll;
    padding: 0 15px 5px;
}

.innerNavigationHeader .rs-nav-item.rs-nav-item-active{
    color: var(--catalina-blue);
}

.innerNavigationHeader.rs-nav div.rs-nav-bar{
    border-top: none;
}

.innerFunctionalityChatList{
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 15px;
}

.innerFunctionalityChatList > svg{
    cursor: pointer;
}

.innerFunctionalityChatList > svg:last-child{
    margin-left: auto;
}

.iconBlockMessages{
    position: absolute;
    bottom: 5px;
    right: 5px;
}

@media (max-width: 800px) {
    .innerUserChat{
        min-width: auto;
    }
    .innerChatList{
        position: absolute;
        inset: 0;
        z-index: 1;
        background-color: #FFFFFF;
        max-width: 100%;
    }

    .innerChatList.hide{
        display: none;
    }
}
