.confirmPurchaseGiftCouponDialog{
    max-width: 500px;
    width: 100%;
}

.confirmPurchaseGiftCouponDialog :global(.rs-modal-content) {
    background-color: var(--swatch--warm-white);
}

.orderInfoClassName{
    padding-bottom: 1rem;
}

.confirmButton{
    display: block;
    margin-left: auto;
}

@media (max-width: 450px) {
    .confirmPurchaseGiftCouponDialog{
        max-width: calc(100% - 20px);
    }
    .confirmButton{
        max-width: 100%;
        width: 100%;
    }
}
