.modalThemeContent {
    &.rs-drawer {
        width: 800px;

        .rs-drawer-body{
            padding-bottom: 72px !important;
        }

        .formManageThemeContent{
            width: 100%;
            padding: 0 15px;
            max-width: 380px;
            max-height: 100%;
            overflow-y: scroll;
        }
    }
}
