.statusCode{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 15px;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 700;
    border: 1px solid transparent;
    height: fit-content;
    transition: all .5s;
}

.statusCode.sm{
    font-size: 12px;
    line-height: 12px;
    padding: 3px 12px;
}

.statusCode.sm .timeIconSubscription{
    width: 12px;
    height: 12px;
}
