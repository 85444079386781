.primaryButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    padding: 6px 10px;
    background: var(--bg-primary-button);
    color: var(--text-primary-button);
    max-width: fit-content;
    border-radius: 7px;
}

.primaryButton.big{
    font-size: 18px;
    line-height: 20px;
    padding: 8px 12px;
}

.primaryButton.delete{
    background-color: var(--persian-red);
    color: #FFFFFF;
}

.primaryButton:disabled{
    opacity: .6;
}


body:has(.userPages) .primaryButton{
    color: var(--swatch--warm-white);
    background-color: var(--swatch--cta-blue);
}
