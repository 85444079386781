.modalConfirm .modal-dialog{
    max-width: 320px;
}

.modalConfirm.md .modal-dialog{
    max-width: 530px;
}

.modalConfirm .btn-close{
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 0;
    padding: 0;
}

.titleModalConfirm{
    font-size: 18px;
    line-height: 20px;
}

.bodyModalConfirm{
    font-size: 16px;
    line-height: 18px;
    white-space: pre-line;
}

.modalConfirm .primaryButton {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 8px;
    border-width: 0;
}

.footerModalConfirm{
    padding: 7px 10px;
}

.modalConfirm.user{
    background-color: initial;
}
