.modalGroupUsers{
    .usersList{
        position: relative;
        height: calc(100% - 135px);
        margin-top: 15px;
        overflow-y: scroll;
    }

    .usersPagination{
        position: fixed;
        bottom: 76px;
        left: 1rem;
        right: 1rem;
    }
}

.innerUserRow{
    display: block;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e3dede;
    transition: background-color .3s;

    &:hover{
        background-color: #f6f5f5;
        border-radius: 10px;
    }
    &:last-child{
        border-bottom: none;
    }

    .checkboxSelectUserGroup{
        label{
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
        }
        .rs-checkbox-wrapper{
            top: 50%;
            transform: translateY(-50%);
        }
    }

}
