.innerInputFile{
    width: 100%;
}

.dropZone{
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.dropZone:hover .wrapperBgHover{
    z-index: 10;
    opacity: 1 !important;
}

.filePreview{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.wrapperBgHover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(112, 110, 110, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
    z-index: 0;
    opacity: 0;
}

.innerAddBtnImage{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    fill: #FFFFFF;
}

.inputFile{
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: pointer;
}

.dropzoneBigLogo{
    width: 250px;
    height: 100px;
}

.dropzoneSmallLogo{
    width: 75px;
    height: 75px;
}
