.innerRadioButtons{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.innerRadioButton{
    display: flex;
    align-items: center;
}

.radioButton{
    width: 20px;
    height: 20px;
    border: 2px solid var(--catalina-blue) !important;
    cursor: pointer;
    margin: 0;
    transition: all .5s;
}

.radioButton:checked{
    background-color: var(--catalina-blue) !important;
}

.labelRadioButton{
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
}
