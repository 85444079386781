.registrationSteps{
    padding: 0 35px 40px;
    width: fit-content;
    min-width: fit-content;
    max-width: 400px;
    position: sticky;
    top: 40px;
    margin-top: 40px;
    height: 100%;
}

.innerStep{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    text-align: right;
    position: relative;
    opacity: 1;
}

.innerStep:not(:last-child):before{
    content: '';
    position: absolute;
    width: 2px;
    height: 50px;
    top: 100%;
    right: 24px;
    background-color: #eae7dc;
    z-index: -1;
}

.innerStep:after{
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    right: -43px;
    background-color: var(--swatch--warm-white);
    z-index: 1;
    border-radius: 50%;
    border: 3px solid #eae7dc;
}

.innerStep:not(:last-child){
    margin-bottom: 50px;
}

.stepTitle{
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
}
.previousRegistrationStep .stepTitle{
    color: #9a9998;
}

.stepDescription{
    font-size: 17px;
    line-height: 19px;
    color: #5c5959;
}

.innerStepIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background-color: var(--swatch--cta-blue);
    border-radius: 50%;
}

.currentRegistrationStep.innerStep{
    opacity: 1;
}
.currentRegistrationStep.innerStep:after{
    background-color: var(--swatch--cta-blue);
}
.previousRegistrationStep .innerStepIcon{
    background-color: #9a9998;
}
.previousRegistrationStep.innerStep:after{
    background-color: #9a9998;
}

.futureRegistrationStep.innerStep .stepTitle,
.futureRegistrationStep.innerStep .stepDescription,
.futureRegistrationStep.innerStep .innerStepIcon{
    opacity: .5;
}

@media (max-width: 991px) {
    .registrationSteps{
        margin-top: 10px;
        top: 10px;
        padding: 20px 18px 20px 15px;
    }

    .innerStep{
        gap: 15px;
    }
    .innerStep:not(:last-child):before{
        right: 18px;
    }

    .innerStep:after{
        right: -26px;
    }
    .innerStep:not(:last-child){
        margin-bottom: 40px;
    }
    .stepTitle{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 0;
    }
    .stepDescription{
        font-size: 14px;
        line-height: 16px;
    }
    .innerStepIcon{
        padding: 10px;
    }
    .stepIcon{
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 645px) {
    .registrationSteps{
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        border-right: none;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-top: 0;
        max-width: 100%;
    }
    .registrationSteps .innerStep .textInfo{
        display: none;
    }
    .innerStep:not(:last-child):before{
        display: none;
    }
    .innerStep:after{
        display: none;
    }
    .innerStep:not(:last-child){
        margin-bottom: 0;
    }
}
