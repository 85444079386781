/*.formPost{*/
/*    max-width: 700px;*/
/*    margin: 20px auto 0;*/
/*    position: relative;*/
/*}*/

.messageEditor{
    height: 100%;
    padding-bottom: 40px;

    .innerMessageInput{
        display: flex;
        flex-direction: column;
        min-height: 60%;
    }
    .formFooter {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #FFFFFF;
        z-index: 10;
        bottom: 0;
        right: 40px;
        left: 280px;
        transition: all .5s;
        padding: 20px 0;
    }
}

.adminLayout:has(.navigationAdmin.hide) {
    .messageEditor .formFooter {
        left: 85px;
    }
}

.selectedType{
    margin-bottom: 10px;
}

.selectedType > span{
    color: #929f83;
    cursor: pointer;
    transition: color .3s;
}

.selectedType > span.active{
    color: var(--button-color);
}

.innerInputDate{
    flex: 1;
    max-width: 230px;
    min-width: 230px;
    width: 100%;
    margin: 0;
}

.innerInputDate .inputStyle{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding: 6px 12px;
    background-color: transparent;
    color: var(--catalina-blue);
    border: 2px solid var(--catalina-blue);
    outline: none;
    border-radius: 7px;
    max-width: fit-content;
}

.innerCountDays{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.titleCountDays{
    font-size: 24px;
    line-height: 26px;
    margin-right: 10px;
}

.timeSelectMessage{
    margin-left: 0;
    max-width: 100px;
    width: 100%;
}

.timeSelectMessage .dropdown-menu{
    min-width: auto;
    width: 100%;
    text-align: center;
}

.textareaMessage{
    margin-bottom: 5px;
}

.textareaMessage > textarea{
    min-height: 170px;
}

.textareaMessage > textarea,
.textareaMessage > textarea:active{
    font-size: 18px;
    line-height: 24px;
    background-color: transparent;
    color: #040849;
    border-width: 2px;
}

.textareaMessage > textarea::placeholder{
    color: rgba(4, 8, 73, .6);
}

.textareaMessage > textarea:focus{
    background-color: transparent;
    color: #040849;
    border-width: 2px;
}

.innerTabAdmin{
    max-width: 1210px;
    margin: 20px auto 0;
}

.buttonCreateContent{
    font-size: 16px;
    line-height: 18px;
    position: static;
    margin: 0 auto;
    max-width: fit-content;
    padding: 10px 15px;
}

.innerAdditionalContent{
    max-width: 700px;
    margin: 0 auto;
}

.messageTagSelector{
    width: 100%;
    margin: 10px 0 5px;
    min-height: 45px;
}

.messageTagSelector .rs-stack{
    font-size: 18px;
    line-height: 24px;
}

.innerButtonsForm{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    margin: 10px 0 30px auto;
}

.submitSettings{
    max-width: 200px;
    padding: 10px;
    width: 100%;
}

.dropdownEmojiPicker  .rs-dropdown-menu{
    z-index: 10;
}
