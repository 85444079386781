.defaultButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    padding: 5px 10px;
    background-color: #FFFFFF;
    border: 1px solid var(--button-color);
    border-radius: 7px;
    color: var(--button-color);
    cursor: pointer;
    transition: all .5s;
}

.defaultButton.equalPadding {
    padding: 4px;
}

.defaultButton.bigEqualPadding {
    padding: 8px;
}

.defaultButton.big{
    font-size: 18px;
    line-height: 20px;
    padding: 6px 12px;
}

.defaultButton:hover{
    color: var(--button-color);
    border-color: var(--button-color) !important;
    background-color: initial;
}

.defaultButton:disabled{
    opacity: .7;
}


body:has(.userPages)  .defaultButton{
    color: var(--swatch--cta-blue);
    background-color: transparent;
    border: 2px solid  var(--swatch--cta-blue);
}

body:has(.userPages)  .defaultButton:hover{
    border-color: var(--swatch--cta-blue) !important;
}


@media(max-width: 991px){
    .defaultButton{
        padding: 7px;
    }
}
