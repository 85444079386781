.formManageGiftPricing{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 60%;
}

.managePreviewPricing{
    width: 40%;
    background: var(--lightest-gray);
    border-left: 1px solid var(--mediun-gray);
    padding: 50px 20px;
}
