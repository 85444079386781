.innerItemNumberPagination{
    min-width: 20px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #d8dee4;
    transition: box-shadow .5s;
}

.stickyPagination{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #FFFFFF;
}

.paginateBlock{
    display: flex;
    align-items: stretch;
    gap: 10px;
    list-style: none;
    max-width: max-content;
    margin: 30px auto 0;
}

.itemNumberPagination{
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.itemNumberPagination:hover{
    color: #000000;
    text-decoration: none;
}

.activePagination{
    border: 1px solid var(--catalina-blue);
}

.activePagination > a{
    color: var(--catalina-blue) !important;
    text-decoration: none !important;
}

.activePagination > a:hover{
    color: #FFFFFF;
    text-decoration: none !important;
}

.breakDots{
    min-width: 50px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 20px;
}

.breakDots > a{
    font-size: 14px;
    line-height: 16px;
    margin: 0 10px;
}

.arrowBtnPagination{
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: var(--border-radius);
    padding: 5px 10px;
    border: 1px solid #d8dee4;
    transition: all .3s;
    user-select: none;
}

.arrowBtnPagination:first-letter{
    text-transform: uppercase;
}

.arrowBtnPagination > a{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    display: block;
    text-align: center;
    color: #000000;
}

.arrowBtnPagination:disabled{
    opacity: .5;
    cursor: not-allowed !important;
}

.arrowBtnPagination:disabled:hover{
    box-shadow: none;
}

.disabledArrow{
    opacity: .5;
    cursor: not-allowed !important;
}

.disabledArrow > a{
    cursor: not-allowed !important;
}
