.arrowButtonAccordion{
    width: 30px;
    height: 30px;
    transition: transform .5s;
}

.arrowButtonAccordionActive{
    transform: rotate(180deg);
}

.tabSurvey .buttonAddNew{
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    background-color: transparent;
    text-decoration: underline;
    color: var(--catalina-blue);
}

.timeActionSurvey > span{
    font-weight: 600;
}

.optionVariant{
    border-radius: 5px;
    padding: 5px 10px 5px 5px;
    margin-bottom: 5px;
    border: 1px solid #7da1d5;
    background-color: #e5ecf6;
}

.questionOptionCircle{
    min-width: 30px;
    padding: 3px 10px;
    border: 1px solid #7da1d5;
    border-radius: 5px;
    background-color: #FFFFFF;
    white-space: nowrap;
    overflow-x: scroll;
}

.questionOption{
    font-size: 18px;
    line-height: 22px;
    white-space: nowrap;
}

.pencilSurvey{
    margin-left: auto;
    cursor: pointer;
}

.surveyOptionInput input{
    color: #000000;
    background-color: #FFFFFF;
}

.dropdownSurveyQuestion .dropHeader{
    background-color: #FFFFFF;
}

.innerDropdownOpenSurveyQuestion{
    max-width: max-content;
}

.innerDropdownOpenSurveyQuestion .inputLabel{
    font-size: 18px;
    line-height: 22px;
    white-space: nowrap;
    margin-left: 0;
}

.innerDropdownOpenSurveyQuestion .dropHeader{
    border: 0;
    border-bottom: 2px solid var(--dropdown-header);
    border-radius: 0;
    padding: 0;
}
