.adminLayout{
    flex: 1;
    display: flex;
}
.adminLayout[data-incomplete-setup-banner="true"]{
    margin-top: 44px;
}

.adminLayout .innerLoadingAdminContent{
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 240px;
    transition: all .3s;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.4);
}

.adminLayout .loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
    height: auto;
}

.adminLayout:has(.navigationAdmin.hide) .innerLoadingAdminContent{
    left: 44px
}
.adminLayout[data-incomplete-setup-banner="true"] .innerLoadingAdminContent{
    top: 104px;
}

.innerInfoSection{
    padding-bottom: 40px;
    transition: max-width .5s;
}

.innerAdminPages{
    position: relative;
    height: calc(100% - 60px);
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 40px;
    overflow: auto;
}
.adminLayout[data-incomplete-setup-banner="true"] .innerAdminPages{
    height: calc(100% - 60px);
}

.containerAdminMainInfo{
    max-width: 1320px;
    margin: 0 auto;
}


.adminHeaderInfoSection{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
    padding: 20px 40px 10px 0;
    background-color: #FFFFFF;
    z-index: 20;
}
.adminLayout[data-incomplete-setup-banner="true"] .adminHeaderInfoSection{
    top: 44px;
}
.btnAdminHeaderInfoSection{
    padding: 0;
    background-color: transparent;
}
.btnAdminHeaderInfoSection:hover{
    background-color: transparent;
}
.btnAdminHeaderInfoSection[aria-checked="true"] svg{
    fill: var(--catalina-blue);
}
.btnAdminHeaderInfoSection.message{
    overflow: visible;
}
.btnAdminHeaderInfoSection.message .indicator{
    position: absolute;
    top: -3px;
    right: -3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--sandy-brown);
    animation: pulse 2s infinite;
}

.titleAdminPage{
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 15px;
}

.blockTitle{
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 20px;
}

.blockTitle.sm{
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
}

.blockTitle.line{
    padding-bottom: 5px;
    border-bottom: 1px solid #e3dede;
}

.innerBlockTextInfo.mw-600{
    max-width: 600px;
}

.innerEmptyBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    border-radius: var(--border-radius);
    border: 1px dashed #e3dede;
}

.blockDescription{
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
    color: rgb(65, 69, 82);
}
.blockDescription.sm{
    font-size: 14px;
    line-height: 16px;
}

.adminLayout .tabsList.bottomLine{
    position: relative;
}
.adminLayout .tabsList.bottomLine:after{
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #e3dede;
    z-index: -1;
}

.adminLayout .tab{
    position: relative;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #969898;
    cursor: pointer;
    background-color: transparent;
    transition: all .3s;
}
.adminLayout .tab.active{
    color: var(--catalina-blue);
}
.adminLayout .tab:before{
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background-color: transparent;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all .3s;
}
.adminLayout .tab.active:before{
    background-color: var(--catalina-blue);
}

.adminLayout .tab.fullLine:before{
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    transform: translateX(0);
    transition: all .3s;
}

.blockInfo{
    padding: 16px;
    border-radius: 8px;
    background-color: #f5f6f8;
}

.blockInfo .blockInfoTitle{
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.blockInfo .blockInfoTitle.sm{
    font-size: 16px;
    line-height: 18px;
}

.blockInfo .blockInfoContent{
    font-size: 16px;
    line-height: 20px;
}
.blockInfo .blockInfoContent.sm{
    font-size: 14px;
    line-height: 18px;
}

.blockInfo .blockInfoContent.mw-800{
    max-width: 800px;
}

.blockInfo .blockInfoContent.mw-900{
    max-width: 900px;
}

/*IncompleteSetupBanner*/
.incompleteSetupBanner{
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 35px;
    background-color: var(--persian-red);
}
.incompleteSetupBanner:after{
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 10px;
    height: 10px;
    background: radial-gradient(180% 180% at right -65% top -65%, transparent 99%, var(--persian-red));
    transform: rotate(90deg);
}
.incompleteSetupBanner:before{
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    width: 10px;
    height: 10px;
    background: radial-gradient(180% 180% at right -65% top -65%, transparent 99%, var(--persian-red));
    transform: rotate(180deg);
}
.attentionText{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
}
.attentionText.link{
    text-decoration: underline !important;

    &:focus{
        color: inherit;
    }
}
.attentionText.link:hover{
    color: #FFFFFF;
}
/*IncompleteSetupBanner*/

/*RSUITE*/
body:has(.adminLayout) {
    .rs-toggle-presentation{
        background-color: #dfd2d2;
    }
    .rs-toggle-checked .rs-toggle-presentation{
        background-color: var(--catalina-blue);
    }
    .drawerActionModalFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
        border-top: 1px solid var(--border-input);
        background-color: #FFFFFF;
        margin: 0;
    }
}
/*RSUITE*/

@media (max-width: 991px) {
    .innerAdminPages{
        padding-right: 20px;
    }
    .adminHeaderInfoSection{
        padding-left: 20px;
        padding-right: 20px;
    }
}
