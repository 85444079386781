.innerMessageInput{
    resize: vertical;
    overflow: auto;
    min-height: 200px;
    box-shadow: none !important;

    textarea{
        height: calc(100% - 10px);
        resize: none;
        border: none;
        padding: 36px 0 0 ;
        background-color: transparent;
        border-radius: 0;
        margin-top: 10px;
        flex: 1;

        &:focus{
            outline: none;
            box-shadow: none;
        }
    }
}

.innerToolbar{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 5px;
    margin: 15px 0 8px;
}

.constantTooltip{
    max-width: 270px;
    padding: 5px 7px;
}

.warningCloseVariables > .tooltip-inner{
    max-width: 260px;
}

.numberMessages{
    text-align: right;
    font-weight: 500;
    color: rgb(118, 118, 118);
}

.numberMessages > span{
    color: #040849;
    font-weight: 700;
}
