.layoutGuest:has(.loginOrg) .bg{
    top: -500px;
}

.loginOrg {
    .textForgotPassword {
        display: block;
        max-width: fit-content;
        font-weight: 600;
        text-decoration: underline !important;
        color: var(--swatch--cta-blue);
        margin-left: auto;
        margin-top: 5px;
    }
}

@media(max-width: 991px){
    .layoutGuest:has(.loginOrg) .bg{
        top: 100px;
    }
}
