.calendars {
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
    transition: all 2s;
    border-radius: var(--border-radius);
}

.viewCalendar {
    animation: view .2s linear;
    animation-fill-mode: forwards;
    transform: scaleY(0);
    transform-origin: top;
    visibility: collapse;
}

.innerLoadingCalendar {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
}

.innerLoadingCalendar .loadingCalendarIcon{
    width: 50px;
    height: 50px;
}

@keyframes view {
    0% {
        transform: scaleY(0);
        visibility: collapse;
    }
    100% {
        transform: scaleY(1);
        visibility: visible;
    }
}

.innerListThemes {
    overflow-x: scroll;
    max-width: 750px;
}

.innerListThemes::-webkit-scrollbar {
    height: 5px;
}

.innerListThemes::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f1f1f1;
}

.innerListThemes::-webkit-scrollbar-thumb {
    background-color: #040849;
    border-radius: 10px;
    outline: none;
}

.calendarListThemes {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    max-width: max-content;
}

.calendarListThemes .themeCard {
    display: flex;
    gap: 10px;
    white-space: nowrap;
    align-items: center;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 1s;
    background-color: #FFFFFF;
}

.calendarListThemes .themeCard > span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: all 1s;
}

.calendarListThemes .themeCard.activeThemeCard > span {
    background-color: #FFFFFF !important;
}

.innerCalendars {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    max-width: max-content;
    background-color: #fff;
    box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
}

.innerListMessages {
    position: absolute;
    height: calc(100% + 10px);
    left: 0;
    right: 0;
    top: calc(-100% - 10px);
    background-color: #FFFFFF;
    z-index: 0;
    transition: all .3s;
}

.innerMessages{
    overflow-y: scroll;
    max-height: calc(100% - 42px);
    padding: 0 10px 15px;
}

.openListMessages {
    top: 0;
    z-index: 1;
}

.innerListMessages::-webkit-scrollbar {
    position: relative;
    width: 4px;
    margin-right: 10px;
}

.innerListMessages::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f1f1f1;
}

.innerListMessages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    outline: none;
}

.innerListMessages::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.35)
}

.listMessages {
    height: 100%;
}

.headerListMessages {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 0;
}

.crossListMessages {
    position: absolute;
    right: 10px;
    transition: all .5s;
    top: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    fill: #0408495e;
}

.titleListMessages {
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.arrowMoveOnMessages {
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 50%;
}

.innerThemes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.innerThemes p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 7px;
    border: 1px solid transparent;
    transition: all .5s;
    white-space: nowrap;
    cursor: pointer;
}

.innerMessage {
    display: flex;
    align-items: baseline;
    gap: 10px;
    flex-wrap: nowrap;
    width: 100%;
}

.deliveryTime {
    white-space: nowrap;
}

.textMessages {
    background-color: rgba(4, 8, 73, 0.09);
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    margin: 10px 0;
    display: block;
    white-space: pre-line;
    word-break: break-word;
}

.textMessages > a{
    text-decoration: underline !important;
}

.calendar + .calendar {
    border-left: 1px solid #040849;
    padding-left: 10px;
}

.calendar {
    width: 100%;
    table-layout: fixed;
    max-width: 360px;
    border-spacing: 5px;
    border-collapse: separate;
}

.titleCalendar {
    caption-side: top;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
}

.headerCalendar {
    text-align: left;
}

.itemHeaderCalendar {
    text-align: center;
}

.listBodyCalendar > td {
    position: relative;
    text-align: center;
    padding: 10px;
    font-weight: 500;
    color: #040849;
    transition: all .5s;
    border: 1px solid transparent;
    border-radius: 10px;
}

.listBodyCalendar > td:hover {
    border: 1px solid rgba(4, 8, 73, .5) !important;
}

.bodyCalendar:before {
    line-height: 10px;
    content: ".";
    color: white;
    display: block;
}


.listBodyCalendar > td.messageReceived {
    background-color: rgba(0, 0, 0, 0.35) !important;
    color: #FFFFFF !important;
}

.listBodyCalendar > td.messageReceived > .eventsMore {
    color: #FFFFFF;
}

.listBodyCalendar > td.notCurrentMonth {
    opacity: .3;
    color: #000000;
}

.listBodyCalendar > td.notCurrentMonth .eventsMore {
    display: none;
}

.listBodyCalendar .activeDate {
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #040849;
    border-radius: 50%;
    width: 7px;
    height: 7px;
}

.events {
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 10px;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}

.eventsMore {
    position: absolute;
    top: -1px;
    right: 0;
    font-size: 13px;
    line-height: 12px;
    font-weight: 700;
    padding: 3px 4px;
    border-radius: 5px;
    color: rgba(52, 55, 103, 84%);
}

.headerFilterCalendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #040849;
    padding: 0 90px 10px 10px;
    margin-bottom: 15px;
}

.innerFilterCalendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.innerFilterCalendar .userDropDown {
    min-width: 160px;
}

.yearsDropdown {
    max-height: 400px;
    overflow-y: scroll;
}

.arrowPrevMonth {
    position: absolute;
    top: 18px;
    left: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: all .5s;
    background-color: transparent;
    border: none;
}

.arrowPrevMonth:disabled {
    opacity: .4;
}

.arrowPrevMonth:disabled:hover {
    background-color: inherit;
}

.arrowPrevMonth:hover {
    background-color: rgba(4, 8, 73, .3);
}

.arrowPrevMonth svg {
    transition: all .5s;
}

.arrowPrevMonth:hover svg {
    fill: #FFFFFF;
}

.arrowPrevMonth:disabled:hover svg {
    fill: #000000;
}

.arrowNextMonth {
    position: absolute;
    top: 18px;
    right: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: all .5s;
    background-color: transparent;
    border: none;
}

.arrowNextMonth:hover {
    background-color: rgba(4, 8, 73, .3);
}

.arrowNextMonth:disabled {
    opacity: .4;
}

.arrowNextMonth:disabled:hover {
    background-color: inherit;
}

.arrowNextMonth svg {
    transition: all .5s;
}

.arrowNextMonth:hover svg {
    fill: #FFFFFF;
}

.arrowNextMonth:disabled:hover svg {
    fill: #000000;
}

.calendarCross {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    z-index: 100;
}

.messageStatus{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    line-height: 12px;
    margin-top: 5px;
    margin-left: auto;
    max-width: fit-content;
}

.messageStatus .point{
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: transparent;
}

/*USER*/
body:has(.userPages)  .calendars{
    border-radius: 1.5rem;
}

body:has(.userPages) .innerCalendars{
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.149);
    border: 1px solid #eae7dc;
    background-color: #f9f0e4;
    border-radius: 1rem;
}

body:has(.userPages) .innerListMessages {
    background-color: #f9f0e4;
}
body:has(.userPages) .listBodyCalendar .activeDate {
    background-color: var(--swatch--cta-blue);
}

/*USER*/
