.themeList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .attachedTheme{
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #e3dede;
    }
}
