.innerBlockAchievements {
    margin-top: 20px;
}

.titleAchievementsType{
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    margin: 40px 0 10px 10px;
}

.innerAchievements {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
}

.achieve {
    position: relative;
    width: 100%;
    max-width: 120px;
    filter: grayscale(98%);
}

.achievementCompeted{
    filter: grayscale(0);
}

.achieveIcon {
    background-color: var(--achieve-background);
    border-radius: 140px;
    width: 120px;
    height: 120px;
    padding: 10px;
    object-fit: contain;
}

.achieveName {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--catalina-blue);
    text-align: center;
    margin-top: 5px;
}

.achievementsCounter{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 70px;
    text-align: center;
    border-radius: 24px;
    margin: -10px auto 0;
    color: #FFFFFF;
    background-color: #069402;
}

@media (max-width: 640px) {
    .innerAchievements {
        justify-content: center;
        gap: 30px;
    }
    .achieve{
        max-width: 80px;
    }
    .achieveIcon{
        width: 80px;
        height: 80px;
    }
    .titleAchievementsType{
        margin-top: 20px;
    }
    .achieveName{
        line-height: 14px;
    }
}
