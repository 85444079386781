.innerDropDownFilter{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.innerDropDownFilter label {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #666666;
    margin-bottom: 5px;
}

.containerAdminMainInfo .userDropDown{
    margin-left: 0;
    width: 100%;
    max-width: max-content;
    min-width: 160px;
}

.userDropDown .itemMenu:first-letter{
    text-transform: uppercase;
}

.userDropDownMenu{
    max-height: 450px;
    overflow-y: scroll;
}

.containerAdminMainInfo .dropdownTitle:first-letter{
    text-transform: uppercase;
}

.crossIconFilter{
    cursor: pointer;
    fill: var(--persian-red);
}

.innerUserSearchInput{
    flex: 1;
}

.appliedFilterCount{
    position: absolute;
    top: -50%;
    right: -50%;
    width: 17px;
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    border-radius: 50%;
    background-color: var(--catalina-blue);
    color: #FFFFFF;
}

.subscriptionImg{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
