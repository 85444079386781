.oldPrice{
    font-size: 14px;
    line-height: 16px;
    text-decoration: line-through;
    position: absolute;
    right: 105%;
    top: 100%;
    transform: translateY(-50%);
}

body:has(.userPages) .oldPrice{
    color: var(--swatch--cta-blue);
}

@media (max-width: 991px) {
    .oldPrice{
        font-size: 13px;
        line-height: 13px;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(0);
    }
}
