/*.userSubscriptionInfo{*/
/*    max-width: 400px;*/
/*    width: 100%;*/
/*    min-width: 300px;*/
/*}*/

/*.innerActionDropdown{*/
/*    position: absolute;*/
/*    top: -40px;*/
/*    right: 30px;*/
/*}*/

.innerUserThemeAnalytic{
    margin-top: 20px;
}

.highlightSmallTitle{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--catalina-blue);
}

.sortSubscriptionInfoTitle{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: gray;
}

.sortSubscriptionInfoTitle .userName{
    font-size: 30px;
    line-height: 32px;
    font-weight: 600;
    color: #000000;
}

.sortSubscriptionInfoTitle .userName:first-letter{
    text-transform: uppercase;
}

.sortSubscriptionInfoTitle .themeName{
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    color: #000000;
}

.sortSubscriptionInfoTitle .themeName:first-letter{
    text-transform: uppercase;
}

.verticalBlockSubscriptionInfo{
    font-size: 16px;
    line-height: 24px;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid rgb(222, 226, 230);
}

.verticalBlockSubscriptionInfo:last-child{
    border-right: none;
}

.verticalBlockSubscriptionInfo .label{
    color: gray;
}

.verticalBlockSubscriptionInfo .innerDeliveryTime input{
    padding: 0;
    border: 0;
    width: 100px;
    text-align: left;
    text-decoration: underline;
    cursor: default;
}

.verticalBlockSubscriptionInfo .innerDeliveryTime input:focus-visible{
    box-shadow: none;
}

.oldPriceAdminUserTheme{
    right: 0;
    bottom: 0;
}
