.titleTable{
    text-align: center;
    margin-bottom: 30px;
}

.customTable{
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    table-layout: fixed;
}

.innerHeadTable{
    height: 50px;
}

.headColumn{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    width: calc(100% / 6);
    overflow: hidden;
}

.rowUserOrder{
    border-bottom: 2px solid #d5d0d0;
    position: relative;
    transition: background-color .3s;
}

.rowUserOrder:hover{
    background-color: #f6f3f3;
}

.bodyTableRow{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #202020;
    height: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
}

.bodyTableRow .statusCode{
    max-width: 180px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
}

.calendarIcon{
    width: 3%;
}

.calendarIcon > svg{
    cursor: pointer;
}

.showMoreBtn{
    color: #040849;
    font-weight: 500;
    cursor: pointer;
}

.bodyTableRow:first-letter {
    text-transform: uppercase;
}

.auxiliaryButton{
    width: 4%;
}

.rowDrag{
    cursor: move;
}

.rowMessage{
    position: relative;
    background-color: rgba(4, 8, 73, 0.09);
    border-radius: 15px;
    margin: 10px 0;
    display: block;
    height: 100%;
    padding-top: 25px !important;
    white-space: pre-line;
}

.emptyTable{
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 15px;
    color: #040849;
    text-align: center;
    width: 100%;
}

.countMessages{
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    top: 5px;
    right: 15px;
    color: #040849;
}

.cardAbsoluteDate .countMessages{
    font-size: 16px;
    top: 9px;
    right: 70px;
}

.additionalMessageInfo{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 3px;
    max-width: fit-content;
    margin-left: auto;
}

.contentCount{
    position: relative;
    padding: 2px 5px 2px 15px;
    border-radius: 5px;
    background-color: var(--catalina-blue);
    color: #FFFFFF;
}

.contentCount::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFFFFF;
}
