.subscriptionCard{
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px 15px;
    border: 1px solid #eae7dc;
    border-radius: 1rem;
    max-width: 350px;
    cursor: pointer;
    background-color: #f9f0e4;
    transition: all .5s;
}

.subscriptionCard:hover{
    transform: scale(1.03);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.149);
}

.subscriptionCard.cancelled{
    cursor: default;
    opacity: 0.7;
}

.subscriptionCard.cancelled:hover{
    transform: scale(1);
    box-shadow: none;
}

.subscriptionCardImg{
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
}

.subscriptionProgramName{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.subscriptionProgramName::first-letter{
    text-transform: uppercase;
}

.subscriptionPriceInfo{
    font-weight: 600;
    color: #000000;
}

.subscriptionPriceInfo.mobile{
    display: none;
}

.subscriptionInfo{
    display: inline-grid;
    padding-right: 20px;
}

.subscriptionInfo .subscriptionPaymentDateInfo{
    display: none;
    text-transform: uppercase;
}

.subscriptionPriceInfo > .price{
    color: #000000;
}

.subscriptionPriceInfo > .periodPay {
    color: #8d8f8f;
}

.subscriptionCard .iconSubscriptionInfo{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background-color: transparent;
}
.subscriptionCard .iconSubscriptionInfo:hover{
    background-color: transparent;
}



.subscriptionCard.sm{
    position: relative;
    gap: 15px;
    padding: 10px 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    max-width: 100%;
}

.subscriptionCard.sm:before{
    content: '';
    position: absolute;
    width: 70%;
    height: 1px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #ced4da;
}
.subscriptionCard.sm:hover {
    transform: scale(1);
    box-shadow: none;
}
.subscriptionCard.sm.cancelled{
    opacity: 1;
}
.subscriptionCard.cancelled.sm:hover{
    transform: scale(1);
    box-shadow: none;
}
.subscriptionCard.sm .subscriptionInfo{
    flex: 1;
    padding-right: 0;
}
.subscriptionCard.sm .subscriptionProgramName{
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 3px;
}
.subscriptionCard.sm .subscriptionPriceInfo{
    display: none;
    font-size: 16px;
    line-height: 20px;
}
.subscriptionCard.sm .subscriptionInfo .subscriptionPaymentDateInfo{
    display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: #969898;
}

.subscriptionCard.sm .subscriptionCardImg{
    width: 45px;
    height: 45px;
}

.subscriptionCard.sm .subscriptionPriceInfo.mobile{
    text-align: right;
    display: block;
}
.subscriptionCard.sm .subscriptionPriceInfo .periodPay{
    font-size: 14px;
}
.subscriptionCard.sm .iconSubscriptionInfo{
    display: none;
}


@media (max-width: 991px) {
    .subscriptionCard{
        position: relative;
        gap: 15px;
        padding: 15px 10px;
        border: none;
        border-radius: 0;
        background-color: transparent;
        max-width: 100%;
    }

    .subscriptionCard:before{
        content: '';
        position: absolute;
        width: 70%;
        height: 1px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: #ced4da;
    }
    .subscriptionCard:hover {
        transform: scale(1);
        box-shadow: none;
    }
    .subscriptionCard.cancelled{
        opacity: 1;
    }
    .subscriptionCard.cancelled:hover{
        transform: scale(1);
        box-shadow: none;
    }
    .subscriptionInfo{
        flex: 1;
        padding-right: 0;
    }
    .subscriptionProgramName{
        font-size: 18px;
        margin-bottom: 3px;
    }
    .subscriptionPriceInfo{
        display: none;
        font-size: 16px;
        line-height: 20px;
    }
    .subscriptionInfo .subscriptionPaymentDateInfo{
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #969898;
    }
    .subscriptionPriceInfo.mobile{
        text-align: right;
        display: block;
    }
    .subscriptionPriceInfo .periodPay{
        font-size: 14px;
    }
    .subscriptionCard .iconSubscriptionInfo{
        display: none;
    }
}
