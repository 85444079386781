.formDetails{
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;

    .footer{
        margin-top: 40px;
        button{
            max-width: 300px;
            width: 100%;
            margin-left: auto;
        }
    }
}

.innerInputDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20%;
    max-width: 100%;
    padding-bottom: 20px;

    &.underline{
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .inputLabel{
        margin: 0;
        white-space: nowrap;
        min-width: 100px;
    }
    .dropdown,
    .inputStyle,
    .dateTimePickerInput{
        width: 100%;
        max-width: 500px;
    }

    .customDropdownMenu{
        width: 100%;
        max-width: 100%;
    }
}

.innerTabInterests{
    margin-top: 10px;
}


@media (max-width: 572px) {
    .formDetails{
        width: 100%;
        max-width: 1000px;
        margin-top: 0;
        margin-bottom: 66px;

        .footer{
            background-color: var(--swatch--warm-white);
            position: fixed;
            z-index: 20;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            padding: 15px 0;
            button{
                max-width: 300px;
                margin: 0 auto;
            }
        }
    }

    .innerInputDetails{
        display: flex;
        gap: 0;
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 0;
        margin-bottom: 10px;

        &.underline{
            margin-bottom: 10px;
            padding-bottom: 0;
            border: 0;
        }

        .inputLabel{
            margin: 4px;
            min-width: auto;
        }
        .dropdown,
        .inputStyle,
        .dateTimePickerInput{
            max-width: 100%;
        }
    }

    .innerTabInterests{
        margin-top: 0;
    }
}
