
.tag{
    display: inline-block;
    max-width: fit-content;
    cursor: default;
    padding: 3px 7px;
    font-weight: 500;
    border-radius: 5px;
    color: #FFFFFF;
    white-space: nowrap;
}

.tag:first-letter{
    text-transform: uppercase;
}

.tag.sm{
    font-size: 13px;
    line-height: 16px;
    padding: 2px 5px;
}
