.innerFeedbackPage{
    max-width: 1200px;
    margin: 0 auto;
}

.titleFeedbackTheme{
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 5px;
}

.filterFeedback{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}

.filterFeedback label {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
    color: #666666;
}

.sortFeedbackByDate{
    font-weight: 500;
    max-width: fit-content;
    margin-left: auto;
    cursor: pointer;
    margin-top: 10px;
    color: #666666;
}
.innerFeedbacks{
    max-width: 1200px;
    margin: 0 auto;
}
.userFeedback{
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 30px;
    padding: 20px 0;
    border-top: 1px solid #ebebec;
}

.userFeedback:first-child{
    border-top: none;
}

.userInfoBlock{
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: flex-start;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
}

.userIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    width: 70px;
    height: 70px;
    background-color: rgba(4, 8, 73, 0.09);
    border-radius: 5px;
    text-transform: uppercase;
}

.feedbackUserName{
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
}

.feedbackAdditionalInfo{
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
    color: #63676d;
}

.feedbackStarRating > .buttonStar{
    font-size: 20px;
    line-height: 20px;
}

.feedbackAdditionalInfo > span{
    font-weight: 500;
    color: #000000;
}

.feedbackMessage{
    flex: 1;
    font-size: 17px;
    line-height: 22px;
    color: #63676d;
}

.feedbackInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.feedbackDate{
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 5px;
    color: #000000;
}

.feedbackType{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    padding: 5px 3px;
    border-radius: 5px;
    max-width: fit-content;
    margin-left: auto;
    border: 1px solid transparent;
    background-color: var(--active-color);
    color: var(--active-color-text);
}

.emptyDataImage{
    width: 100%;
    height: 600px;
    object-fit: contain;
}

.innerSurveyAnalytics .emptyDataImage{
    height: 300px;
}
