.audioDropzone{
    width: 100%;
    height: 60px;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    overflow: hidden;
    font-size: 15px;
    line-height: 17px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    background-color: rgba(112, 110, 110, .1);
    transition: background-color .3s;
}

.uploadAudioLabel{
    font-size: 15px;
    line-height: 17px;
    color: var(--catalina-blue);
}

.audioDropzone > *{
    transition: all .3s;
}

.audioDropzone:hover{
    background-color: rgba(112, 110, 110, .15);

    > * {
        transform: scale(1.05);
    }
}

.inputAudioFile{
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: pointer;
}
