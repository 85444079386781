.passwordStrengthMeter{
    padding: 10px 0 0;
}

.passwordStrengthText{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;
    padding-right: 5px;
    margin-top: 4px;
}

body:has(.userPages){
    .passwordStrengthMeter{
        .rs-progress-line-inner{
            background-color: var(--border-input);
        }
    }
}
