.btnBack{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 5px 10px;
    max-width: max-content;
    border-radius: 10px;
    border: 2px solid var(--catalina-blue);
    color: var(--catalina-blue);
    transition: all .3s;
}

.btnBack:hover{
    color: var(--catalina-blue);
}

.btnBack > svg{
    margin-right: 5px;
    fill: var(--catalina-blue);
}
