.userPages .header{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 26;
    display: flex;
    align-items: center;
    margin: 0 20px 0 10px;
    padding: 15px 0;
    background-color: var(--swatch--warm-white);
}

.userOrgSwitcher.innerOrganizationSwitcher {

     .btnSwitcher .smallLogo{
         background-color: var(--swatch--warm-white);
     }
    .btnSwitcher:hover{
        background-color: #eee4d8;
    }
    .organizationList{
        z-index: 24;
    }

    .organizationListItem[aria-checked="true"]{
        background-color: var(--swatch--cta-blue);
    }
}

.btnToggleMenu{
    padding: 14px 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color .3s;
    margin-right: 10px;
    height: 44px;
    width: 44px;

    &:hover{
        background-color: #eee4d8;
    }

    span{
        display: block;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        background-color: #969898;
    }
    span + span{
        margin-top: 5px;
    }

    &.cross{
        span{
            margin: 0;
            transform-origin: center;
        }
        span:first-child{
            transform: rotate(45deg);
        }
        span:nth-child(2){
            display: none;
        }
        span:last-child{
            margin-top: -2px;
            transform: rotate(-45deg);
        }
    }
}

.innerHeaderUserName{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;

    span{
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
@media (max-width: 991px){
    .userPages .header {
        z-index: 23;
    }
}

@media (max-width: 576px) {
    .userPages .header {
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;

        .userOrgSwitcher {
            display: none;
        }
    }
    .innerHeaderUserName {
        margin-left: 0;
        span {
            display: none;
        }
    }
}
