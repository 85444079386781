.modalSelectThemes{
    .themesList{
        position: relative;
        height: calc(100% - 135px);
        margin-top: 15px;
        overflow-y: scroll;
    }

    .themesPagination{
        position: fixed;
        bottom: 76px;
        left: 1rem;
        right: 1rem;
    }
}
