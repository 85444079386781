.dropHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 6px 10px;
  background-color: #FFFFFF;
  border: 2px solid var(--dropdown-header);
  border-radius: var(--border-radius);
}

.dropdownTitle{
  font-size: 16px;
  line-height: 20px;
  color: var(--inner-dropdown);
}

.innerArrow{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  transition: all .5s;
}

.innerDropdown.show .innerArrow{
  transform: rotate(180deg);
}


.errorDropdown{
  border-color: var(--error);
}

.errorDropdown > p{
  color: var(--error);
}

.disabledDropdown{
  background-color: #e9ecef;
  opacity: 1;
  cursor: not-allowed;
}

/*USER*/
body:has(.userPages) .dropHeader{
  background-color: #f9f0e4;
}
/*USER*/
