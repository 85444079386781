.dateTimeRangePicker{
    display: block;
    border: 2px solid var(--border-input);
    border-radius: var(--border-radius);
    width: 100%;
}

.dateTimeRangePicker .rs-picker-toggle.rs-btn{
    border-radius: 10px;
    border: none
}

.dateTimeRangePicker .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.dateTimeRangePicker .rs-picker-toggle-placeholder{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

.dateTimeRangePicker:hover .rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover{
    border-color: var(--border-input);
}
