.innerChatMessage{
    padding-top: 10px;
}

.chatMessage{
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    word-wrap: break-word;
    border-radius: var(--border-radius);
    background: rgb(236, 238, 238);
    width: fit-content;
    margin-right: 30px;
    white-space: pre-line;
}

.userCurrentMessage .chatMessage{
    color: #FFFFFF;
    background-color: var(--catalina-blue);
    margin-right: 0;
    margin-left: auto;
}

.time{
    text-align: right;
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: rgb(112, 117, 121);
    margin-top: 5px;
}

.userCurrentMessage .chatMessage .time{
    color: #FFFFFF;
}
