.imageLoader{
    position: relative;

    .placeholderImage{
        object-fit: cover !important;
    }
}

.imageLoader .iconLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    height: auto;
}

