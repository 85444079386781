.userLogin{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 20px;
    margin: 0 auto;
    overflow: hidden;
}

.innerContentUserLogin{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
    width: 50%;
    height: 100%;
}

.appLogoLogin{
    max-width: 160px;
    margin-bottom: 30px;
}

.innerFormUserLogin{
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.formUserLogin{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.titleUserLogin{
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 70px;
}

.buttonUserLogin{
    font-size: 20px;
    line-height: 26px;
    width: 100%;
    background-color: var(--swatch--cta-blue);
    border-radius: 1.5rem;
    padding: 0.6rem 1rem;
    color: #FFFFFF;
}

.textResetCode{
    text-align: center;
    margin-top: 15px;
}

.imgUserLogin{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(.8);
}

.footerUserLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
}

.linkTermsPrivacy{
    text-decoration: underline !important;
}

.footerUserLogin .dot{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 0 14px;
    background-color: #000000;
}

@media (max-width: 991px) {
    .innerContentUserLogin{
        position: relative;
        width: 100%;
        z-index: 2;
    }
    .imgUserLogin{
        left: 0;
        width: 100%;
        z-index: 0;
    }
    .darkerBg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 244, 230, 0.7);
        z-index: 1;
    }
}

@media (max-width: 540px) {
    .innerContentUserLogin{
        padding-top: 60vw;
    }
    .innerFormUserLogin{
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
    }
    .formUserLogin{
        flex: 1;
    }
    .appLogoLogin{
        display: none;
    }
    .titleUserLogin{
        margin-bottom: 30px;
    }
    .imgUserLogin{
        height: 100vw;
        width: 150vw;
        left: -25vw;
        top: -50vw;
        object-position: bottom;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        box-shadow: 10px 20px 30px 0 rgba(0, 0, 0, 0.149);
    }
    .innerFooterFormUserLogin{
        margin-top: auto;
        margin-bottom: 30px;
    }
    .darkerBg{
        display: none;
        z-index: -5;
    }
    .footerUserLogin{
        margin-top: 10px;
    }
}
