.tableImageCell{
    border-radius: 6px;
    overflow: hidden;
    display: inline-block;
}

.tableImageCell > img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.innerRatingListCell .buttonStar{
    font-size: 14px;
    line-height: 14px;
}

.innerMessageCell{
    background-color: rgba(4, 8, 73, 0.09);
    border-radius: 15px;
    display: block;
    padding: 5px 10px 0;
    white-space: pre-line;
}

.messageContent{
    overflow: hidden;
    width:100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.themeMessageTable .rs-table-cell-wrap:has(.innerMessageCell){
    flex: 1;
}

.tagsList{
    padding-bottom: 7px;
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 5px;
    width: 100%;
    justify-content: flex-end;
}
