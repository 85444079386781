.innerComingSoon{
    margin-top: 100px;
}

.comingSoonText{
    font-size: 58px;
    line-height: 64px;
    font-weight: 700;
    letter-spacing: 10px;
    word-spacing: 15px;
    text-align: center;
}

.comingSoonSupText{
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 991px) {
    .innerComingSoon{
        margin-top: 40px;
    }
    .comingSoonText{
        letter-spacing: 2px;
        word-spacing: 5px;
        font-size: 28px;
        line-height: 32px;
    }
    .comingSoonSupText{
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }
}
