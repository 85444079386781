.formManageSurvey{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formManageSurvey .selectedType{
    font-size: 24px;
    line-height: 24px;
}

.formManageSurvey .titleCountDays{
    display: none;
}
