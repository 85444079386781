.layoutGuest{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;

    .container{
        max-width: 1116px;
        padding: 0 18px;
        margin: 0 auto;
    }

    .guestHeader{
        width: 100%;
        padding: 22px 16px;
        background: transparent;

        .logo{
            width: 160px;
            height: 30px;
        }
    }

    .innerCenterForm{
        flex: 1;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 40px;
        margin-top: -100px;

        .innerForm{
            padding: 30px;
            border-radius: var(--border-radius);
            max-width: 540px;
            width: 100%;
            background-color: #f9f0e4;
            box-shadow: 0 15px 35px 0 rgba(220, 212, 202, 0.3),0 5px 15px 0 rgba(220, 212, 202, 0.3);

            .form{
                display: grid;
                gap: 0 20px;
                grid-template-columns: repeat(2, 1fr); /* Two equal columns */
                grid-template-rows: auto auto; /* Two rows */
            }

            .innerInput{
                margin-top: 15px;
                height: 67px;
                transition: all .3s ease-in-out;
                grid-column: span 2;
                grid-row: auto;

                &.password{
                    height: 104px;
                }

                &.borderLine{
                    height: 87px;
                    border-bottom: 1px solid #eae7dc;
                    padding-bottom: 15px;
                }

                &.firstOneLine{
                    grid-column: 1;
                }
                &.secondOneLine{
                    grid-column: 2;
                }


                .inputStyle{
                    line-height: 26px;
                }

                .inputLabel{
                    margin-bottom: 5px;
                }

                &.otpCode{
                    height: 40px;
                    margin-top: 0;
                }
                &.hide{
                    visibility: hidden;
                    overflow: hidden;
                    opacity: 0;
                    margin: 0;
                    padding: 0;
                    height: 0;
                }
            }

            .titleForm{
                font-size: 26px;
                line-height: 28px;
                font-weight: 500;
                margin-bottom: 15px;
            }
            .submitFormButton{
                width: 100%;
                max-width: 100%;
                margin-top: 20px;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            .innerFormFooter{
                grid-column: span 2;
                grid-row: auto;
            }

            .blockUnderFooter{
                margin: 0 -24px -32px;
                padding: 25px 0;
                text-align: center;

                a{
                    color: var(--swatch--cta-blue);
                    font-weight: 700;
                    text-decoration: underline !important;
                }
            }
        }
    }

    .bg{
        scrollbar-gutter: stable;
        width: 100vw;
        position: fixed;
        top: -250px;
        bottom: 0;
        left: 0;
        overflow: hidden;
        transform: skewY(-12deg);
        background-color: #f9f0e4;
        z-index: -1;

        .bgFirst{
            transform-origin: 0;
            height: 1698px;
            background-color: #eee4d8;
            position: absolute;
            top: -1000px;
            left: 0;
            right: 0;
        }

        .bgFirstLeftThinkShare{
            position: absolute;
            height: 40px;
            top: 668px;
            left: 0;
            right: calc(50% + 540px);
            background-color: rgba(var(--swatch--cta-blue-rgb-sring), 35%);
        }
        .bgSecondLeftThinkShare{
            position: absolute;
            height: 40px;
            top: 698px;
            left: -10px;
            right: calc(50% + 405px);
            background-color: rgba(var(--swatch--cta-blue-rgb-sring), 75%);
        }
        .bgFirstRightThinkShare{
            position: absolute;
            height: 40px;
            top: 658px;
            left: calc(50% + 430px);
            right: -10px;
            background-color: rgba(var(--swatch--cta-blue-rgb-sring), 90%);
        }
    }
}

@media (max-width: 991px) {
    .layoutGuest {
        .guestHeader {
            padding: 18px;
        }

        .innerCenterForm {
            padding-top: 105px;
            align-items: flex-start;

            .innerForm {
                padding: 30px 20px;

                .form {
                    gap: 0 10px;
                }

                .innerInput{
                    margin-top: 10px;

                    &.borderLine{
                        height: 80px;
                        padding-bottom: 13px;
                        margin-bottom: 3px;
                    }
                }

                .titleForm {
                    font-size: 24px;
                    line-height: 26px;
                }

                .submitFormButton{
                    font-size: 17px;
                }
                .blockUnderFooter{
                    margin: 10px -12px -29px;
                }
            }
        }

        .bg{
            top: 0;
            bottom: auto;
            overflow: visible;

            .bgFirst{
                height: 480px;
                top: -300px;
                left: 0;
                right: 0;
            }

            .bgFirstLeftThinkShare{
                width: 126px;
                top: 150px;
                left: calc(50% + 190px);
            }
            .bgSecondLeftThinkShare{
                right: 0;
                width: 800px;
                top: 180px;
                left: calc(50% + 67px);
            }
            .bgFirstRightThinkShare{
                width: calc(50% - 191px);
                top: 50px;
                left: 0;
                right: auto;
            }
        }
    }
}


@media (max-width: 894px) {
    .layoutGuest {
        .innerCenterForm{
            justify-content: center;
            min-width: auto;
        }
    }
}
