.selectTime{
    z-index: 10;
    width: 100%;
}

.selectTime > .rc-time-picker-input{
    text-align: center;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 6px 10px;
    border: 2px solid var(--border-input);
}
body:has(.userPages) .selectTime > .rc-time-picker-input{
    background-color: #f9f0e4;
}
.selectTime.sm > .rc-time-picker-input{
    padding: 4px 7px;
}

.selectTime > .rc-time-picker-input:focus-visible{
    box-shadow: 0 0 0 0.25rem rgba(34, 73, 87, .25);
    outline: none;
}

.rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
}


.rc-time-picker-clear{
    display: none;
}

.rc-time-picker-clear,
.rc-time-picker-clear-icon:after {
    font-size: 11px;
}

.rc-time-picker-panel-select{
    width: 50px;
}

.rc-time-picker-panel-select li{
    font-size: 10px;
}

.rc-time-picker-panel-select::-webkit-scrollbar,
.rc-time-picker-input::-webkit-scrollbar,
.rc-time-picker-panel-input::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.listTime{
    width: 150px;
    padding-top: 33px;
    z-index: 1051;
}

.listTime .rc-time-picker-panel-input-wrap{
    display: none;
}

/*USER_INPUT_STYLES*/
body:has(.userPages) .selectTime > .rc-time-picker-input:focus-visible
{
    box-shadow: 0 0 0 0.25rem rgba(183, 178, 172, 0.3);
    border-color: var(--border-input);
}

body:has(.userPages) .inputStyle:disabled{
    opacity: .8;
    cursor: not-allowed;
    background-color: #e5ded4;
}
/*USER_INPUT_STYLES*/
