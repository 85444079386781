.filterGiftPricing{
    display: flex;
    gap: 10px;
}

.innerGiftPricingSearch{
    max-width: 250px;
    width: 100%;
}

.giftImgItem{
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.tableGiftPricing{
    border-collapse: collapse;
    max-width: 100%;
    table-layout: auto;
}

.tableGiftPricing .innerHeadTable{
    height: auto;
}

.tableGiftPricing td{
    padding: 8px;
    width: auto;
    text-align: left;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:first-child{
    width: 1px;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:nth-child(2){
    width: 1px;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:nth-child(3){
    width: 1px;
    vertical-align: top;
    white-space: nowrap;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:nth-child(6){
    text-align: right;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:nth-child(7){
    width: 1px;
    text-align: left;
    white-space: nowrap;
    padding-left: 10px;
}

.tableGiftPricing .innerHeadTable > tr > .headColumn:nth-child(8){
    width: 1px;
    text-align: center;
    white-space: nowrap;
}

.rowItemGiftPricing{
    border-top: 1px solid lightgray;
}

.rowItemGiftPricing:last-child{
    border-bottom: 1px solid lightgray;
}

.rowItemGiftPricing > td:nth-child(3){
    width: 1px;
}

.rowItemGiftPricing > td:nth-child(4){
    max-width: 100px;
    height: 1px;
}

.rowItemGiftPricing > td:nth-child(6){
    text-align: right;
}

.rowItemGiftPricing > td:nth-child(7){
    width: 1px;
    white-space: nowrap;
    padding-left: 10px;
}

.rowItemGiftPricing > td:nth-child(8){
    width: 1px;
    white-space: nowrap;
}

.giftPriceItem{
    color: rgb(104, 115, 133);
}

.totalResult{
    color: rgb(104, 115, 133);
}

.toggleStatus.rs-toggle-checked .rs-toggle-presentation{
    background-color: var(--bg-primary-button);
}

.threePointsButton{
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all .5s;
}

.threePointsButton:hover{
    border: 1px solid var(--catalina-blue);
}
