.innerModalSupport{
    display: flex;
    align-items: center;
    padding: 25px;
    border-radius: 15px;
    border: none;
    background-color: var(--swatch--warm-white);
}

.iconSupport{
    background-color: var(--swatch--beige);
    padding: 10px;
    border-radius: 50%;
}

.titleModalSupport{
    font-size: 30px;
    line-height: 32px;
    font-weight: 400;
    margin: 20px 0;
}

.textModalSupport{
    font-size: 18px;
    line-height: 20px;
    text-align: center;
}

.textModalSupport > a{
    font-weight: 500;
    text-decoration: underline !important;
}

.crossModalSupport{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

@media (max-width: 991px) {
    .innerModalSupport{
        padding: 10px 5px 20px;
    }

    .iconSupport{
        padding: 10px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .titleModalSupport{
        font-size: 24px;
        line-height: 28px;
        margin: 5px 0 10px;
    }

    .textModalSupport{
        font-size: 16px;
    }
    .crossModalSupport{
        width: 20px;
        height: 20px;
    }
}
