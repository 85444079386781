.subtitleSurveyResults{
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

.innerVotePercentageBar{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex: 1;
    max-width: 1000px;
}

.innerDropdownSurveyQuestion{
    width: fit-content;
    max-width: 600px;
    min-width: 300px;
}

.innerDropdownSurveyQuestion .dropdown-menu{
    min-width: 100%;
}

.innerTableSurveyAnswers .emptyTable{
    max-width: 700px;
}

.tableSurveyAnswers.customTable{
    max-width: 1000px;
    margin: 0;
}

.tableSurveyAnswers .headColumn:nth-child(1){
    width: 5%;
}

.tableSurveyAnswers .headColumn:nth-child(2){
    width: 25%;
}

.tableSurveyAnswers .headColumn:nth-child(3){
    width: 75%;
}
