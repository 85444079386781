.innerListUserGiftInfo{
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
    max-height: 120px;
    overflow-y: scroll;
}

.userGiftInfo{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    padding: 4px 10px;
    background-color: var(--catalina-blue);
    border-radius: 10px;
    color: #FFFFFF;
}

.activeGiftPricingBg {
    background-color: var(--catalina-blue);
}

.disableGiftPricingBg{
    background-color: var(--darkest-gray);
}

.innerTooltipGiftAnalytic{
    padding: 10px;
    background-color: #FFFFFF;
    border: 1px solid #cccccc;
}

.dropdownPicketPricingIds{
    max-width: 600px;
}

.tableGiftAnalyticPurchaserInfo,
.tableGiftAnalyticCouponName{
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2px;
}

.tableGiftAnalyticPurchaserInfo,
.tableGiftAnalyticCouponInfo{
    font-size: 13px;
}

.tableGiftAnalyticCouponInfo .statusCode {
    font-size: 13px;
    line-height: 13px;
    margin-left: 3px;
}
