.switchOrganizationList{
    flex: 1;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 298px));
    gap: 15px 0;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.orgCard{
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 16px;
    border-left: 2px solid #e3dede;
    cursor: pointer;
    position: relative;
    transition: background-color .5s;

    &:first-child{
        border-left: none;
    }
    &:nth-child(3n - 2){
        border-left: none;
    }

    .orgLogo{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        transition: all .5s;
    }

    .orgInfo{
        display: grid;
        max-width: 100%;
        transition: all .5s;

        .orgName{
            font-weight: 500;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .orgDescription{
            font-size: 14px;
            color: #969898;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &:hover{
        background-color: rgba(0, 0, 0, 0.03);
        .orgLogo{
            transform: scale(1.03);
        }
        .orgInfo{
            transform: scale(1.03);
        }
    }

    &.active{
        cursor: default;

        &:before{
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            width: 10px;
            height: 10px;
            z-index: 1;
            border-radius: 50%;
            background-color: var(--catalina-blue);
            animation: pulse 2s infinite;
            transform: translateY(-50%) !important;
        }

        &:hover{
            background-color: transparent;
            .orgLogo{
                transform: scale(1);
            }
            .orgInfo{
                transform: scale(1);
            }
        }
    }
}

body:has(.userPages){
    .orgCard{
        border-color: #eae7dc;

        &.active:before{
            background-color: var(--swatch--cta-blue);
        }
    }
}


@media (max-width: 830px) {
    .switchOrganizationList {
        justify-content: space-between;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

        .orgCard {
            justify-content: center;
            flex-direction: column;
            border-left: none;
            padding-left: 5px;
            padding-right: 5px;

            &.active{
                background-color: rgba(0, 0, 0, 0.03);
            }
            &.active:before{
                display: none;
            }

            .orgLogo {
                width: 60px;
                height: 60px;
            }

            .orgInfo {
                text-align: center;
            }
        }
    }
}

@media(max-width: 520px){
    .switchOrganizationList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 0;
        height: 100%;

        .orgCard {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            padding-left: 10px;
            padding-right: 10px;
            &.active:before{
                display: block;
                left: 5px;
                top: 5px;
            }

            .orgInfo {
                text-align: left;
            }
        }
    }
}
