.customCheckbox.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before{
    background-color: var(--catalina-blue);
    border-color: var(--catalina-blue);
}

.customCheckbox label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before{
    border-color: var(--catalina-blue);
}

body:has(.userPages) .customCheckbox.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before{
    background-color: var(--swatch--cta-blue);
    border-color: var(--swatch--cta-blue);
}

body:has(.userPages) .customCheckbox label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before{
    border-color: var(--swatch--cta-blue);
}
