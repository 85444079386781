.imageEmptyBlock{
    max-width: 150px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 10px;
}

.titleEmptyBlock{
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
}

.supTitleEmptyBlock{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

@media (max-width: 991px) {
    .imageEmptyBlock{
        max-width: 100px;
    }
}
