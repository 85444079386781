.registration{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    flex: 1;
}

.registrationHeader{
    padding: 60px 0 45px;
    border-bottom: 2px solid #eae7dc;
}

.titleRegistration{
    font-size: 38px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.titleDescriptionRegistration{
    color: #5c5959;
}

.innerRegistrationFlow{
    display: flex;
    align-items: stretch;
}

.innerForms{
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 35px;
    padding-top: 20px;
    border-left: 2px solid #eae7dc;
}

.innerForms > form{
    width: 100%;
}

.innerHeaderFormRegistration{
    padding-bottom: 25px;
    border-bottom: 2px solid #eae7dc;
    margin-bottom: 25px;
    display: none;
}

.registration .titleForm{
    font-size: 38px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 10px;
}

.descriptionForm{
    font-size: 17px;
    line-height: 19px;
    color: #5c5959;
}

.footerForm{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin: 40px 0;
}

.registration .actionButtons{
    display: flex;
    align-items: center;
    gap: 25px;
}

.additionalRegistrationButton{
    font-size: 17px;
    line-height: 19px;
    font-weight: 600;
    color: #5c5959;
    background-color: transparent;
}

.additionalRegistrationButton.btnBack{
    margin-right: auto;
}

.registration .submitButton{
    font-size: 17px;
    line-height: 19px;
    font-weight: 600;
    padding: 15px 25px;
    background-color: var(--swatch--cta-blue);
    color: #FFFFFF;
    border: 2px solid  var(--swatch--cta-blue);
    border-radius: 10px;
}

.registration .submitButton:disabled{
    opacity: 0.8;
}

.innerDeliveryPreferences{
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    align-items: stretch;
    gap: 10px 20px;
    flex-wrap: wrap;
}

.innerDeliveryPreferences .innerTimezoneDropdown{
    grid-column: span 2;
    max-width: 400px;
}

.innerTagList{
    grid-column: span 2;
}

.registration .tagList{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.registration .tag{
    font-size: 17px;
    line-height: 19px;
    border: 2px solid var(--swatch--cta-blue);
    border-radius: 10px;
    color: var(--swatch--cta-blue);
    padding: 5px 10px;
    cursor: pointer;
    transition: all .5s;
}

.registration .tag.selected{
    background-color: var(--swatch--cta-blue);
    color: var(--swatch--warm-white);
}

.innerStepsRegistration{
    position: relative;
    min-height: 30px;
}

.registration .innerPaymentForm{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-end;
    min-height: 100px;
}

.loadingRegistrationForm{
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(6px);
    z-index: 10;
}

.loadingRegistrationForm .loading{
    width: 40px;
    height: 40px;
}

.listInfoDelivery{
    margin-top: 20px;
}

.innerDeliveryPreferences .innerDeliverySettings{
    max-width: 400px;
    grid-column: span 2;
    margin-top: 10px;
}

.termsAndConditions a{
    text-decoration: underline !important;
    color: var(--swatch--cta-blue);
}

.innerSuccessRegistration{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 50px;
}
.innerSuccessRegistration .successIcon{
    max-width: 150px;
}
.titleSuccessRegistration{
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-top: 10px;
}
.descriptionSuccessRegistration{
    font-size: 18px;
    line-height: 20px;
}
.descriptionSuccessRegistration .themeName{
    font-weight: 600;
}
.descriptionSuccessRegistration .themeName:first-letter{
    text-transform: uppercase;
}
.innerSuccessRegistration .innerButtons{
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 25px;
}
.innerSuccessRegistration .innerButtons button{
    max-width: 350px;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
}
.innerSuccessRegistration .logo{
    max-width: 150px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.paymentForm .titleForm{
    display: none;
}

.paymentForm .innerUserInfo{
    display: flex;
    gap: 15px;
    align-items: flex-start;
}

.paymentForm .innerUserInfo > *{
    flex: 1;
    width: 100%;
}

.registration .paymentForm .paymentInfo{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    max-width: 500px;
    margin: 20px auto 25px;
    text-align: center;
}

.registration .paymentForm .paymentInfo > span:before{
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #5c5959;
    margin-right: 5px;
    margin-bottom: 4px;
}
.registration .paymentForm .paymentInfo > span.firstInLine:before{
    display: none;
}

.registration .innerCouponField{
    max-width: calc(50% - 7.5px);
}

.registration .innerInputPromotionCode .inputStyle{
    padding-right: 70px;
}

.registration .applyPromotionCode{
    position: absolute;
    right: 15px;
    top: 50%;
    padding: 5px;
    transform: translateY(-50%);
    font-weight: 600;
    color: var(--swatch--cta-blue);
    background-color: transparent;
    z-index: 10;
}

.registration .promotionCodeInfo{
    font-size: 16px;
    line-height: 18px;
    margin-top: 5px;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .registrationHeader{
        padding: 30px 0 20px;
        border-bottom: 2px solid #eae7dc;
    }
    .titleRegistration{
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    .titleDescriptionRegistration{
        font-size: 14px;
        line-height: 14px;
    }
    .registration .innerInputsOtp{
        max-width: 320px;
        margin: 0 auto;
    }
    .innerForms{
        padding-left: 20px;
    }
    .innerHeaderFormRegistration{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .registration .titleForm{
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    .descriptionForm{
        font-size: 15px;
        line-height: 20px;
    }
    .footerForm{
        gap: 10px;
        margin: 20px 0;
    }
    .registration .actionButtons{
        gap: 20px;
    }
    .additionalRegistrationButton{
        font-size: 15px;
        line-height: 15px;
    }
    .registration .submitButton{
        font-size: 15px;
        line-height: 15px;
        padding: 10px 15px;
    }
    .innerDeliveryPreferences{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .registration .tag{
        font-size: 15px;
        line-height: 15px;
    }
    .loadingRegistrationForm .loading{
        width: 30px;
        height: 30px;
    }
    .listInfoDelivery{
        margin-top: 15px;
    }
    .innerDeliverySettingsStep{
        max-width: 100%;
    }
    .innerSuccessRegistration{
        flex: 1;
        justify-content: center;
        margin-top: 30px;
    }
    .innerSuccessRegistration .successIcon{
        max-width: 100px;
    }
    .titleSuccessRegistration{
        font-size: 22px;
        line-height: 24px;
    }
    .descriptionSuccessRegistration{
        font-size: 16px;
        line-height: 18px;
    }
    .innerSuccessRegistration .innerButtons{
        margin-top: 15px;
    }
    .innerSuccessRegistration .innerButtons button{
        max-width: 230px;
    }
    .innerSuccessRegistration .logo{
        max-width: 100px;
        margin-top: 30px;
    }
    .paymentForm .innerUserInfo{
        flex-direction: column;
        width: 100%;
    }
    .registration .innerCouponField{
        max-width: 100%;
    }
    .registration .paymentForm .paymentInfo{
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@media (max-width: 645px) {
    .registration{
        margin-bottom: 80px;
    }
    .registration .paymentForm .paymentInfo{
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .innerRegistrationFlow{
        flex-direction: column;
    }
    .innerForms{
        padding-left: 0;
        padding-top: 0;
        border-left: 0;
    }
    .descriptionForm{
        margin-top: 15px;
    }
    .innerHeaderFormRegistration{
        display: block;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 10px;
    }
    .footerForm{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        background: var(--swatch--warm-white);
        margin: 0;
        z-index: 10;
        border-top: 2px solid #eae7dc;
    }
    .innerSuccessRegistration{
        margin-top: 20px;
    }
    .innerSuccessRegistration .innerButtons{
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .innerSuccessRegistration .innerButtons button{
        max-width: 350px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .listInfoDelivery{
        margin-bottom: 40px;
    }
    .innerSuccessRegistration .logo{
        max-width: 100px;
        margin-top: 30px;
    }
    .innerDeliveryPreferences .innerTimezoneDropdown,
    .innerDeliveryPreferences .innerDeliverySettings{
        max-width: 100%;
    }
    .registration .titleForm{
        text-align: center;
    }
    .paymentForm .titleForm{
        display: block;
    }
}
