.themeSettings{
    .toggleThemeStatus{
        .rs-toggle-presentation{
            background-color: var(--main-inactive-theme-status);
        }
        &.rs-toggle-checked .rs-toggle-presentation{
            background-color: var(--main-active-theme-status);
        }
    }
    .subscriptionPrice{
        font-size: 18px;
        line-height: 21px;
        color: rgb(65, 69, 82);

        span:first-letter{
            text-transform: uppercase;
        }
    }

    .btnThemeSettingsDropdownMenu{
       padding: 4px
    }

    .jumpShakingAnimation{
        animation: jump-shaking 0.83s 3;
        animation-delay: 1s;
    }

    @keyframes jump-shaking {
        0% { transform: translateX(0) }
        25% { transform: translateY(-9px) }
        35% { transform: translateY(-9px) rotate(17deg) }
        55% { transform: translateY(-9px) rotate(-17deg) }
        65% { transform: translateY(-9px) rotate(17deg) }
        75% { transform: translateY(-9px) rotate(-17deg) }
        100% { transform: translateY(0) rotate(0) }
    }

    .emergencyContentTitle{
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .iconSettings {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: #f5f6f8;
        border-radius: var(--border-radius);

        svg {
            fill: var(--catalina-blue);
        }
    }

    .integrationBtn{
        font-size: 17px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 7px;
        padding: 5px 10px;
        background-color: #e9e1e1;
        color: var(--catalina-blue);
        transition: all .5s;
    }
    .integrationBtn:hover{
        transform: scale(1.03);
    }
    .integrationBtn img{
        width: 25px;
        height: 25px;
        border-radius: 5px;
    }
}
