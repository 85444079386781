.innerOrganizationSwitcher{
    position: relative;
    max-width: fit-content;
    width: 100%;
    background-color: transparent;
    padding: 0;
}
.innerOrganizationSwitcher .btnSwitcher{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    font-size: 18px;
    padding: 6px 7px;
    width: fit-content;
    max-width: 100%;
    transition: all .5s;
    border-radius: 6px;
    background-color: transparent;
}

.navigationAdmin.hide .innerOrganizationSwitcher .btnSwitcher{
    border-radius: 0;
}

.innerOrganizationSwitcher .btnSwitcher:hover{
    background-color: #ece8e8;
}

.innerOrganizationSwitcher .btnSwitcher .smallLogo{
    width: 30px;
    height: 30px;
    object-fit: contain;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.innerOrganizationSwitcher .btnSwitcher .organizationName{
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    /*max-width: 92px;*/
    overflow: hidden;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    visibility: visible;
    transition: all .3s;
}
.innerOrganizationSwitcher .btnSwitcher .arrow{
    fill: #969898;
    transition: all .3s;
}
.innerOrganizationSwitcher .btnSwitcher:hover .arrow,
.innerOrganizationSwitcher:has(.innerOrganizationList[data-show-list="true"]) .btnSwitcher .arrow{
    opacity: 1;
    visibility: visible;
    transition: all .5s;
    fill: #000000;
}
.innerOrganizationSwitcher:has(.innerOrganizationList[data-show-list="true"]) .btnSwitcher .arrow{
    transform: rotate(180deg);
}

.navigationAdmin.hide .innerOrganizationSwitcher .btnSwitcher .organizationName,
.navigationAdmin.hide .innerOrganizationSwitcher .btnSwitcher .arrow{
    opacity: 0;
    visibility: hidden;
}

.innerOrganizationList{
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #e3dede;
    border-radius: 10px;
    background-color: #FFFFFF;
    z-index: 12;
    width: 300px;
    overflow: hidden;
    padding-top: 7px;
    padding-bottom: 7px;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 82, .08),0 3px 6px 0 rgba(0, 0, 0, .12)
}

.organizationList{
    max-height: 307px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.navigationAdmin.hide .innerOrganizationList{
    left: 10px;
}

.innerOrganizationList[data-show-list="false"]{
    display: none;
}

.organizationListItem{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 15px;
    color: #969898;
    transition: all .5s;
    cursor: pointer;
}
.organizationListItem:hover{
    background-color: #ece8e8;
}
.organizationListItem[aria-checked="true"]{
    background-color: var(--catalina-blue);
}
.organizationListItem .smallLogo{
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-color: #FFFFFF;
    border-radius: 5px;
}
.organizationListItem .organizationName{
    font-size: 16px;
    line-height: 20px;
}
.organizationListItem[aria-checked="true"] .organizationName{
    color: #FFFFFF;
}

.innerOrganizationList .innerBtnCreateCompany{
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e3dede;
}

.innerOrganizationList .innerBtnCreateCompany .btnCreateCompany{
    justify-content: space-between;
    width: 100%;
    color: #000000;
    background-color: transparent;

    &:hover{
        background-color: #ece8e8;
    }
}
