.innerWeekDaysBtn{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.weekdayBtn{
    font-weight: 500;
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    border-radius: 10px;
    border: 2px solid var(--border-input);
    background-color: #FFFFFF;
    transition: all .3s;
}

.weekdayBtn.active{
    background-color: var(--catalina-blue);
    color: #FFFFFF;
    border-color: var(--catalina-blue);
}

.weekdayBtn.error{
    border-color: var(--persian-red);
}

body:has(.userPages) .weekdayBtn{
    border-color: #eae7dc;
    background-color: #f9f0e4;
}

body:has(.userPages) .weekdayBtn.active{
    border-color: #eae7dc;
    background-color: #bdbab1;
}

@media (max-width: 991px) {
    .weekdayBtn{
        font-size: 15px;
        line-height: 17px;
        width: 37px;
        height: 37px;
    }
}
