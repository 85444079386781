.listHistoryUserActivities > div{
    padding-left: 10px;
}

.listHistoryUserActivities > div:last-child .innerIconUserActivities{
    box-shadow: none;
}

.contentUserActivities{
    padding-bottom: 20px;
}

.innerIconUserActivities{
    display: flex;
    box-shadow: inset 1px 0 #c1c4c7;
}

.innerIconUserActivities > svg{
    background-color: #FFFFFF;
    transform: translateX(calc(-50% + 0.5px));
}

.activitiesTitle{
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 3px;
}

.activitiesTime{
    font-size: 14px;
    line-height: 16px;
    color: rgb(84, 89, 105);
}

.btnPaginateHistoryUserActivities{
    padding: 3px 10px;
    color: #FFFFFF;
    background: var(--catalina-blue);
    border-radius: 8px;
}

