.innerInputsOtp{
    gap: 5px;
}

.otpInput{
    font-size: 20px;
    line-height: 26px;
    padding: 6px 10px !important;
    color: #000000;
    border-radius: 10px;
    border: 2px solid var(--border-input);
    width: 100% !important;
}

.otpInput::placeholder{
    color: #b6bdd4;
}

.otpInput:focus{
    box-shadow: 0 0 0 0.25rem rgba(34, 73, 87, .25);
    outline: 0;
    transition: all .5s;
}

.errorInputOtp{
    color: var(--persian-red) !important;
    border-color: var(--persian-red) !important;
    transition: all .5s;
}

.errorInputOtp::placeholder{
    color: var(--persian-red) !important;
}

/*USER_INPUT_STYLES*/

body:has(.userPages) .otpInput{
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    background-color: var(--swatch--warm-white);
    border-color: var(--border-input);
}

body:has(.userPages) .otpInput::placeholder{
    color: rgba(0, 0, 0, 0.5);
}
/*USER_INPUT_STYLES*/
