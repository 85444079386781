.customTooltipContentHistory{
    background-color: #FFFFFF;
    border: 1px solid rgb(204, 204, 204);
    width: 100%;
    padding: 7px 7px 7px;
}

.customTooltipContentHistory .title{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 5px;
}

.statusContentHistory{
    display: block;
    font-size: 16px;
    line-height: 22px;
}

.contentStatus{
    font-weight: 500;
    padding: 2px 7px;
    border-radius: 5px;
    color: #FFFFFF;
}

.dateCreateContentEvent{
    font-size: 15px;
    line-height: 17px;
    display: block;
    margin-top: 7px;
    text-align: right;
}
