.innerMessageSettings{
    max-width: 350px;
}

.titleSettingsMessageCont{
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: justify;
}

.innerMessageSettings > span{
    display: block;
}

.messageConstant{
    font-size: 13px;
    line-height: 15px;
}

.messageConstant + .messageConstant{
    margin-top: 4px;
}
