.innerDropdownPicker{
    width: 100%;
    min-width: 300px;
}

.innerDropdownPicker .rs-picker-toggle-placeholder{
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

.errorCustomDropdownPicker{
    color: var(--persian-red) !important;
    border-color: var(--persian-red) !important;
}

.errorCustomDropdownPicker .rs-picker-toggle-placeholder{
    color: var(--persian-red) !important;
}

/*USER_STYLES*/
body:has(.userPages)  .dropdownPicker {
    background-color: #f9f0e4 !important;
}

body:has(.userPages)  .dropdownPicker .rs-picker-toggle.rs-btn{
    background-color: #f9f0e4 !important;
}

body:has(.userPages) .dropdownPicker .rs-tag{
    background-color: var(--swatch--cta-blue);
    color: #FFFFFF;
}
/*USER_STYLES*/
