.cardDeliveryWeekDay{
    position: relative;
    padding: 15px 15px;
    width: 100%;
    border-top: 1px solid #eae7dc;
}
.cardDeliveryWeekDay:last-child{
    border-bottom: 1px solid #eae7dc;
}
.weekDayName{
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
}
.listDeliveryTime{
    display: flex;
    align-items: center;
    gap: 15px;
}
.addDeliveryTimeBtn{
    font-weight: 600;
    padding: 0 5px;
    background-color: transparent;
    color: var(--swatch--cta-blue);
}
.cardDeliveryWeekDay .deliveryTime > .rc-time-picker-input{
    padding: 5px 15px 5px 35px;
    width: 125px;
    text-align: right;
}
.cardDeliveryWeekDay .deliveryTime .innerIconClock{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    cursor: pointer;
}

.cardDeliveryWeekDay.unselected .weekDayName,
.cardDeliveryWeekDay.unselected .deliveryTime input,
.cardDeliveryWeekDay.unselected .deliveryTime .innerIconClock
{
    opacity: 0.5;
}
.cardDeliveryWeekDay.unselected .deliveryTime input,
.cardDeliveryWeekDay.unselected .deliveryTime .innerIconClock
{
    cursor: not-allowed;
}

@media (max-width: 991px) {
    .cardDeliveryWeekDay{
        padding: 8px 5px;
    }
    .weekDayName{
        font-size: 16px;
        line-height: 18px;
    }
    .listDeliveryTime{
        gap: 10px;
    }
    .cardDeliveryWeekDay .deliveryTime > .rc-time-picker-input{
        font-size: 15px;
        line-height: 15px;
        padding: 5px 15px 5px 35px;
        width: 120px;
    }
}
