.leftSideBar {
    position: sticky;
    left: 0;
    top: 74px;
    width: 190px;
    min-width: 190px;
    margin-right: 24px;
    z-index: 25;
    display: flex;
    flex-direction: column;
    background-color: var(--swatch--warm-white);
    height: calc(100vh - 74px);
    padding: 0 5px 20px;
    transition: all .3s;

    .itemMenu{
        display: flex;
        align-items: center;
        padding: 10px 15px;
        width: 100%;
        margin-top: 10px;
        border-radius: var(--border-radius);
        background-color: transparent;
        transition: all .3s;

        &:hover{
            background-color: #eee4d8;
        }
        &:active{
            background-color: #eee4d8;
        }

        .iconMenu{
            fill: #969898;
            min-width: 24px;
        }
        span{
            font-size: 16px;
            line-height: 18px;
            font-weight: 500;
            opacity: 1;
            visibility: visible;
            margin-left: 15px;
            white-space: nowrap;
            transition: all .3s;
        }

        &.itemMenuActive{
            .iconMenu{
                fill: var(--swatch--cta-blue);
            }
            span{
                color:  var(--swatch--cta-blue);
            }
        }
    }

    &.hide{
        width: 65px;
        min-width: 65px;
        .itemMenu {
            span {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .sideBarHeader{
        display: none;
    }
}

@media (max-width: 991px) {
    .leftSideBar{
        position: fixed;
        left: 0;
        top: 0;
        width: 270px;
        min-width: 270px;
        visibility: visible;
        margin-right: 0;
        height: 100vh;

        .itemMenu{
            padding: 8px 12px;
            margin-left: 7px;
            margin-right: 7px;
            width: auto;
            margin-top: 5px;
        }

        &.hide{
            transform: translateX(-100%);
            width: 270px;
            min-width: 270px;
            visibility: hidden;
        }

        .sideBarHeader{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0 15px 10px;
        }
    }
}

@media (max-width: 572px) {
    .leftSideBar{
        width: 300px;
        min-width: 300px;

        &.hide{
            width: 300px;
            min-width: 300px;
        }
        .sideBarHeader{
            display: block;
            padding-right: 10px;
            margin-bottom: 10px;
        }

        .userOrgSwitcher{
           max-width: 100%;
            .btnSwitcher{
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;

                &:hover{
                    background-color: transparent;
                }

                .smallLogo{
                    width: 70px;
                    height: 70px;
                }

                .imageLoader{
                    width: 100%;
                }
            }
            .innerOrganizationList{
                width: 100%;
            }
        }
    }
}
