.contextMenu{
    padding: 5px;
    max-width: fit-content;
    min-width: 150px;
    box-shadow: 0 0 10px rgba(0,0,0,.15);
}
div.contexify_itemContent{
    font-size: 14px;
    font-weight: 600;
    min-height: 24px;
    padding: 4px 12px;
    transition: background-color .5s;
}
.itemContextMenu:not(.contexify_item-disabled):hover > div.contexify_itemContent{
    color: #000000;
    background-color: rgba(112, 117, 121, 0.08);
}

.itemContextMenu > div.contexify_itemContent{
    display: flex;
    align-items: center;
    gap: 15px;
}