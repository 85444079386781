.innerMessageInfo{
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    margin-top: 15px;
    align-items: flex-start;
}

.innerMessageInfo .innerMessageSettings{
    width: 55%;
    max-width: inherit;
}