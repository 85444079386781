.blockStatus{
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--border-radius);
    background-color: var(--persian-red-transparent-6);
    padding: 5px 8px;
    border-radius: var(--border-radius);
    color: #FFFFFF;
}

.modalCancelAllSubscriptions .modal-dialog{
    max-width: 600px;
}
