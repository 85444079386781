.listMessagesTypes{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 16px;
    margin-bottom: 20px;

    .messageType{
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        flex: 1;
        text-align: left;
        padding: 11px;
        border: 1.6px solid #e3dede;
        color: #969898;
        border-radius: 8px;
        background-color: transparent;
        transition: all .3s;

        &:only-child{
            max-width: 450px;
        }

        &.active{
            color: var(--catalina-blue);
            border-color: var(--catalina-blue);
        }
    }
}
