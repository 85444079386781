
@media (max-width: 991px) {
    .registrationOrg{
        .headerRegistrationOrg{
            padding: 18px;
        }
    }
}

@media (max-width: 894px) {
    .registrationOrg {
        .innerMessages {
            display: none;
        }
    }
}
