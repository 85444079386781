.inputStyle{
    font-size: 16px;
    line-height: 20px;
    background-color: #FFFFFF;
    border-radius: var(--border-radius);
    width: 100%;
    padding: 6px 10px;
    color: #000000;
    border: 2px solid var(--border-input);
}

.inputStyle:active,
.inputStyle:focus
{
    box-shadow: 0 0 0 0.25rem rgba(34, 73, 87, .25);
    color: #000000;
}

.inputStyle:disabled{
    background-color: #e9ecef;
    opacity: 1;
    cursor: not-allowed;
}

.inputStyle::placeholder{
    color: rgba(0, 0, 0, 0.5);
}

.inputStyle.errorInputText{
    color: var(--persian-red) !important;
    border-color: var(--persian-red) !important;
}

.inputStyle.errorInputText::placeholder{
    color: var(--persian-red) !important;
}

.innerPriceInput{
    position: relative;
}
.innerPriceInput:after{
    content: '$';
    position: absolute;
    left: 10px;
    bottom: 9px;
    font-size: 16px;
    line-height: 20px;
}
.innerPriceInput input{
    padding-left: 25px;
}

.inputLabel{
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 3px 5px;
    color: #000000;
}

.inputBottomDescription{
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
    margin-left: 3px;
    color: var(--darkest-gray);
}

.innerSearchInput{
    position: relative;
}

.innerSearchInput .inputStyle{
    padding-left: 35px;
}

.innerSearchInput:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    background-size: contain;
    background-position: center;
    background-image: url("/public/images/icons/search.svg");
    z-index: 1;
}

/*USER_INPUT_STYLES*/
body:has(.userPages) .inputStyle{
    background-color: #f9f0e4;
}
body:has(.userPages) .inputStyle:active,
body:has(.userPages) .inputStyle:focus
{
    box-shadow: 0 0 0 0.25rem rgba(183, 178, 172, 0.3);
    color: #000000;
    border: 2px solid var(--border-input);
    background-color: #f9f0e4;
}

body:has(.userPages) .inputStyle:disabled{
    opacity: .8;
    cursor: not-allowed;
    background-color: #e5ded4;
}
/*USER_INPUT_STYLES*/


@media (max-width: 991px) {
    .inputLabel{
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 1px 3px;
    }
}
