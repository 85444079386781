.innerDropdown{
    max-width: fit-content;
    min-width: 300px;
}

.customDropdownMenu{
    max-height: 210px;
    overflow-y: scroll;
}

.dropdownQuestionTypes{
    max-width: max-content;
}

.customDropdownMenu .itemMenu{
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.customDropdownMenu itemMenu:active{
    background-color: var(--catalina-blue);
}

body:has(.userPages) .customDropdownMenu .itemMenu:active{
    background-color: var(--swatch--cta-blue);
}

.itemIconDropdownMenu{
    width: 16px;
    height: 16px;
    margin-right: 6px;
}
