.subTitleAdmin{
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 20px;
}

.customTooltip{
    padding: 10px;
    max-height: 385px;
    overflow-y: scroll;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204)
}

.countContentStatus{
    font-size: 14px;
    font-weight: 500;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    text-align: center;
    color: #FFFFFF;
}
