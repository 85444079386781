.userSubscriptionSearch{
    max-width: 350px;
    width: 100%;
}

.userSubscriptions{
    margin-bottom: 20px;
}

.listUserSubscriptions{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 346px));
}

.innerEmptySubscriptionList{
    max-width: 600px;
}

@media (max-width: 991px) {
    .listUserSubscriptions{
        display: block;
    }
}
