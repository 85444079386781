.tableThemeName{
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2px;
}

.innerListSmallThemeCard{
    display: flex;
    align-items: center;
    gap: 10px 7px;
    flex-wrap: wrap;
    max-height: 64px;
    overflow-y: scroll;
}
