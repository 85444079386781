.navigationAdmin{
    min-width: 240px;
    width: 240px;
    padding: 20px 0 30px;
    position: sticky;
    left: 0;
    top: 0;
    margin-right: 30px;
    transition: all .5s;
    z-index: 22;
    border-right: 1px solid #e3dede;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    height: 100vh;
}
.navigationAdmin.hide{
    min-width: 45px;
    width: 45px;
}
.adminLayout[data-incomplete-setup-banner="true"] .navigationAdmin{
    top: 44px;
    height: calc(100vh - 44px);
}

.innerMenuAdmin{
    margin-top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
}

.adminMenuLink{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 18px;
    padding: 6px 7px 6px 13px;
    color: #969898;
    max-width: 200px;
    width: 100%;
    margin: 0 auto 10px;
    white-space: nowrap;
    transition: all .5s;
    border-radius: 6px;
}
.adminMenuLink:hover{
    color: #969898;
    background-color: #ece8e8;
}
.adminMenuLink:focus{
    color: #969898;
}

.adminMenuLink.activeMenuAdmin{
    color: var(--catalina-blue);
}
.navigationAdmin.hide .adminMenuLink{
    border-radius: 0;
}
.navigationAdmin .adminMenuLink span{
    opacity: 1;
    visibility: visible;
    transition: all .3s;
}
.navigationAdmin.hide .adminMenuLink span{
    opacity: 0;
    visibility: hidden;
}

.adminMenuLink.space{
    position: relative;
    margin-bottom: 30px;

    &:after{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #e3dede;
        border-radius: 5px;
    }
}

.iconAdminMenu{
    color: #969898;
    fill: #969898;
    min-width: 18px;
}
.activeMenuAdmin > .iconAdminMenu{
    color: var(--catalina-blue);
    fill: var(--catalina-blue);
}

.btnCollapseAdminMenu{
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 14;
}

.btnCollapseAdminMenu div{
    width: 2px;
    height: 10px;
    border-radius: 2px;
    background-color: #e3dede;
    transition: all .3s;
}
.btnCollapseAdminMenu div:nth-child(2){
    margin-top: -4px
}
.btnCollapseAdminMenu:hover div:first-child{
    transform: rotate(45deg);
    transform-origin: center;
}
.btnCollapseAdminMenu:hover div:last-child{
    transform: rotate(-45deg);
    transform-origin: center;
}
.navigationAdmin.hide .btnCollapseAdminMenu:hover div:first-child{
    transform: rotate(-45deg);
    transform-origin: center;
}
.navigationAdmin.hide .btnCollapseAdminMenu:hover div:last-child{
    transform: rotate(45deg);
    transform-origin: center;
}

.innerOrganizationSwitcher.adminOrgSwitcher{
    margin: 0 auto 5vh;
    max-width: 200px;
}


.adminLayout[data-incomplete-setup-banner="true"] .adminMenuLink.hiddenDuringSetup{
    display: none;
}

.adminLayout .additionalBottomBtns{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 7px;
    max-width: 200px;
    width: 100%;
    margin: 0 auto 10px;
    white-space: nowrap;
}

.adminLayout .navigationAdmin.hide .additionalBottomBtns{
    padding: 0;
    justify-content: center;
}
