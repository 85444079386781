.globalWidowChat{
    flex: 1;
}

.innerChatTab .btnBack{
    display: none;
}

.globalWidowChat .chatMessage{
    font-size: 16px;
    line-height: 21px;
    padding: 6px 8px 6px 10px;
    max-width: calc(100% - 5.5625rem);
}

.globalWidowChat > div > div{
    padding: 0 40px;
}

.loading.loadingChatMessages{
    height: 100%;
    width: 70px;
}

@media (max-width: 991px) {
    .globalWidowChat > div > div{
        padding: 0 20px;
    }
    .innerUserChat{
        min-width: auto;
    }
    .innerChatTab .btnBack{
        display: block;
    }
    .innerChatTab .innerPreviewChat{
        justify-content: space-between;
    }
    .innerChatTab .innerPreviewChat .infoPreviewChat{
        display: none;
    }
    .innerChatTab .userIcon.userPreviewMessage{
        min-height: 40px;
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
}
