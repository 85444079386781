.innerUserVotePercentageBar{
    font-size: 16px;
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(226, 226, 227, 0.65);
}

.answerProgressFill{
    position: absolute;
    left: 0;
    top: 0;
    bottom:0;
    z-index: -1;
    border-radius: 10px;
}

.percentVoteAnswer{
    font-weight: 900;
    min-width: 50px;
}

.questionAnswer{
    font-weight: 600;
}
