.confirmInvitationForm{
    display: grid;
    gap: 0 20px;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-template-rows: auto auto; /* Two rows */

    .buttonSubmit{
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
        display: block;
        grid-column: span 2;
        grid-row: auto;
    }
}

@media (max-width: 991px) {
    .confirmInvitationForm{
        gap: 0 10px;
    }
}
