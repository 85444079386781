.businessSettingsIconBG{
    position: absolute;
    fill: #e3dede;
    width: 700px;
    max-width: 700px;
    max-height: 700px;
    height: 700px;
    right: -175px;
    top: 0;
    z-index: -5;
}

.formOrganizationInfo{
    max-width: 450px;
}

.innerInputLabelContactLink{
    max-width: fit-content;
}

.innerInputLabelContactLink .inputStyle{
    background-color: transparent;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 0;
    max-width: fit-content;
    box-shadow: none !important;
}

/*PREVIEW_CONTENT*/
.previewContact{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
}
.previewContact .logo{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 50%;
}

.previewContact .name{
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
}

.listContactList li{
    padding-bottom: 5px;
    margin-bottom: 8px;
    border-bottom: 1px solid #beb9b9;
}

.listContactList li:last-child{
    margin-bottom: 0;
}

.listContactList .labelLink{
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.listContactList .link{
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline !important;
}
/*PREVIEW_CONTENT*/

.inputDeleteOrganization{
    width: 100%;
    max-width: 400px;
}

.innerContactMessage{
    height: 230px;
}
