.dateTimePickerInput{
    width: 100%;
}

.dateTimePickerInput .rs-picker-toggle{
    font-size: 16px;
    line-height: 20px;
    border-radius: var(--border-radius);
    color: #000000;
    border: 2px solid var(--border-input);
    transition: all .15s;
    padding: 6px 10px !important;
}

body:has(.userPages) .dateTimePickerInput .rs-picker-toggle{
    background-color: #f9f0e4 !important;
}

.dateTimePickerInput .rs-picker-toggle-value{
    color: #000000;
}

body:has(.userPages) .dateTimePickerInput .rs-picker-toggle-textbox{
    background-color: var(--swatch--warm-white);
}

body:has(.userPages) .dateTimePickerInput .rs-picker-toggle:focus-visible,
body:has(.userPages) .dateTimePickerInput .rs-picker-toggle:focus{
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(34, 73, 87, .25);
    color: #000000;
    border: 2px solid var(--border-input) !important;
}
body:has(.userPages) .dateTimePickerInput .rs-picker-toggle-active{
    padding: 6px 10px;
    border: 2px solid var(--border-input) !important;
    box-shadow: 0 0 0 0.25rem rgba(34, 73, 87, .25);
}
.dateTimePickerInput .rs-picker-toggle:hover{
    border: 2px solid var(--border-input) !important;
}
 .dateTimePickerInput.errorDateTimePickerInput .rs-picker-toggle{
    border: 2px solid var(--persian-red);
}
