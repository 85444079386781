.formTag{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
}

.formChangeTag{
    margin: 10px 0;
}

.previewTag{
    margin-bottom: 5px;
}

.innerTagInner{
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.labelInputTag{
    display: block;
    font-weight: 500;
    margin-bottom: 4px;
}

.tagName{
    line-height: 26px;
    font-weight: 500;
    height: 100%;
    padding: 5px;
    color: #040849;
    border-radius: 7px;
    border: 1px solid rgba(4, 8, 73, .3);
    transition: all .5s;
}

.tagName:focus-visible{
    outline: none;
}

.errorTagName::placeholder{
    color: var(--error);
}

.tagColor{
    border: none;
    padding: 0;
    border-radius: 5px;
    height: 40px;
    cursor: pointer;
}

.tagColor::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
}
.tagColor::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 3px;
}

.arrowRepeatTag{
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.innerColorsChanges{
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.titleManageList{
    text-align: center;
}

.emptyManageList{
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin: 15px auto 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #040849;
}

.topMenu{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 10px;
}

.innerPageManageList{
    max-width: 600px;
    margin: 0 auto;
}

.innerInputSearch{
    position: relative;
    height: 30px;
    border-radius: 7px;
}

.searchIcon{
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.inputSearchList{
    line-height: 26px;
    font-weight: 500;
    height: 100%;
    padding-left: 30px;
    padding-right: 5px;
    color: #040849;
    border-radius: 7px;
    border: 2px solid rgba(4, 8, 73, .3);
    transition: all .5s;
}

.inputSearchList:focus-visible{
    outline: none;
    border-color: rgba(4, 8, 73, .5);
    box-shadow: 0 0 0 1px rgba(4, 8, 73, .5);
}

.inputSearchList::placeholder{
    color: rgba(4, 8, 73, .5);
}

.innerButtonsManage{
    display: flex;
    align-items: center;
    gap: 5px;
}

.buttonNewItemList{
    border: 2px solid var(--button-color);
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 6px;
}

.buttonNewItemList:disabled{
    opacity: .3;
    cursor: not-allowed;
    filter: saturate(100%) !important;
}


.buttonCancel{
    color: #FFFFFF;
    opacity: .83;
    background-color: rgba(33, 38, 45, 1);
    margin-left: 5px;
}

.customTableManageList{
    max-width: 600px;
    width: 100%;
    margin: 10px auto 0;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 10px 5px;
}

.headColumnManageList{
    text-align: left;
    max-width: fit-content;
}

.tableManageListColumn1{
    width: 50%;
}

.tableManageListColumn2{
    width: 30%;
    text-align: center;
}

.tableManageListColumn3{
    width: 10%;
}

.modalDeleteTag .modal-dialog{
    max-width: 500px;
}

