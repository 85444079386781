.formGiftCouponPurchase{
    flex: 1;
    padding: 4.125rem 0;
    max-width: 1300px;
    margin: 0 auto;
}

.innerGiftCard{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.giftCardPreview{
    width: 40%;
    overflow: hidden;
}

.listGiftCoupons{
    grid-column: 1 / 3;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
    overflow-x: scroll;
    padding: 15px 10px 20px;
}

.listGiftCoupons:has(:only-child) {
    visibility: hidden;
    height: 0;
    width: 0;
}

.listGiftCoupons::-webkit-scrollbar {
    width: 1px;
    height: 2px;
}

.giftImg{
    min-width: 80px;
    object-fit: cover;
    object-position: top;
    height: 70px;
    cursor: pointer;
    border-radius: 6px;
    transition: all .5s;
}

.selectedGift,
div.selectedGift{
    box-shadow: 5px 6px 15px 0 rgba(0, 0, 0, 0.5);
}

.innerFormPurchaseGift{
    width: 60%;
    padding-left: 90px;
}

.formGiftCouponName{
    font-size: 2.3rem;
}

.titleFormPurchaseGift{
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.formPurchaseGiftCoupon{
    display: flex;
    align-items: flex-end;
    gap: 1.3rem 10px;
    flex-wrap: wrap;
}

.formPurchaseGiftCoupon .innerInputNameReceiver{
    flex: 1 0 calc(50% - 5px);
}

.formPurchaseGiftCoupon .innerInputEmailReceiver{
    flex: 1 0 calc(50% - 5px);
}

.formPurchaseGiftCoupon .innerInputMessage{
    width: 100%;
    flex: 1 1 auto;
}

.formPurchaseGiftCoupon .innerInputSenderName{
    width: 100%;
    flex: 0 0 calc(50% - 5px);
}

.formPurchaseGiftCoupon .innerInputDeliveryTime{
    width: 100%;
    flex: 1 0 calc(50% - 5px);
}

.giftSubmitButton{
    display: block;
    font-weight: 500;
    min-width: 250px;
    max-width: 250px;
    margin-left: auto;
}

.emptyGiftListImg{
    max-width: 200px;
}

@media (max-width: 991px) {
    .innerGiftCard{
        margin: 0 auto;
    }
    .giftCardPreview{
        width: 100%;
    }
    .innerFormPurchaseGift{
        padding-left: 50px;
        width: 100%;
    }
    .formPurchaseGiftCoupon{
        gap: 1.5rem 10px;
    }
    .formPurchaseGiftCoupon .innerInputNameReceiver{
        flex: 1 0 auto;
        width: 100%;
    }
    .formPurchaseGiftCoupon .innerInputEmailReceiver{
        flex: 1 0 auto;
        width: 100%;
    }
    .formPurchaseGiftCoupon .innerInputSenderName{
        flex: 0 0 auto;
        width: 100%;
    }
    .formPurchaseGiftCoupon .innerInputDeliveryTime{
        flex: 1 0 auto;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .innerGiftCard{
        flex-direction: column;
    }
    .innerFormPurchaseGift{
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
    }
    .formPurchaseGiftCoupon .innerInputNameReceiver{
        flex: 1 0 calc(50% - 5px);
    }
    .formPurchaseGiftCoupon .innerInputEmailReceiver{
        flex: 1 0 calc(50% - 5px);
    }
    .formPurchaseGiftCoupon .innerInputSenderName{
        width: 100%;
        flex: 0 0 calc(50% - 5px);
    }

    .formPurchaseGiftCoupon .innerInputDeliveryTime{
        width: 100%;
        flex: 1 0 calc(50% - 5px);
    }
}

@media (max-width: 450px) {
    .formPurchaseGiftCoupon .innerInputNameReceiver{
        flex: 1 0 auto;
        width: 100%;
    }
    .formPurchaseGiftCoupon .innerInputEmailReceiver{
        flex: 1 0 auto;
        width: 100%;
    }
    .formPurchaseGiftCoupon .innerInputSenderName{
        flex: 0 0 auto;
        width: 100%;
    }

    .formPurchaseGiftCoupon .innerInputDeliveryTime{
        flex: 1 0 auto;
        width: 100%;
    }
    .giftSubmitButton{
        max-width: 100%;
        width: 100%;
        margin: 0;
    }
}

