.userPages{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.innerUserPages{
    flex: 1;
    display: flex;
    align-items: stretch;
}

.userContent{
    flex: 1;
    padding: 10px 20px 40px;
}

.wrapperProfile{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.containerUserLayout{
    position: relative;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.titleProfileHead{
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 30px;
}

.titleProfileSecond{
    font-size: 32px;
    line-height: 36px;
}

.titleProfileThird{
    font-size: 24px;
    line-height: 26px;
}

.titleProfileFourth{
    font-size: 14px;
    line-height: 16px;
    color: #969898;
}

.dropzoneTitle{
    overflow: hidden;
    max-width: 100%;
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdownMenuZone{
    max-height: 500px;
    overflow-y: scroll;
}

.inputSearchDropdown {
    position: sticky;
    top: 0;
    left: 0;
    font-size: 16px;
    width: 100%;
    line-height: 20px;
    margin: 0 0 5px;
    border: none;
    border-bottom: 1px solid #040849;
    padding: 10px 15px 10px;
}

.inputSearchDropdown:focus{
    outline: none;
}

.labelChangePhone{
    font-size: 1.2rem;
    font-weight: 500;
    color: #040849;
}

.innerActiveElements{
    display: flex;
    align-items: center;
    gap: 10px;
}

.userPages .tabsList{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.userPages .tab{
    position: relative;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: #969898;
    cursor: pointer;
    background-color: transparent;
    transition: all .3s;
}
.userPages .tab.active{
    color: #000000;
}
.userPages .tab:before{
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background-color: transparent;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all .3s;
}
.userPages .tab.active:before{
    background-color: #000000;
}

.tabBlock{
    position: absolute;
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
}

.tabBlock.visibleTabBlock{
    position: static;
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: .5s;
    overflow: visible;
}

.userBlockInfo{
    position: relative;
    padding: 20px 15px;
    border: 1px solid #eae7dc;
    border-radius: 1rem;
    max-width: 400px;
    background-color: #f9f0e4;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.149);
    transition: all .5s;
}

.userBlockInfo.hover:hover{
    transform: scale(1.03);
}

.userBlockInfoTitle{
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.userBlockSupText{
    font-size: 16px;
    line-height: 18px;
    color: #969898;
}

.innerLoader{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 244, 230, 0.7);
}

body:has(.adminLayout) .innerLoader{
    background-color: rgba(255, 255, 255, 0.44);
}

.loading.loaderIcon{
    height: auto;
}

.userPages .menuBackdrop{
    display: none;
    position: fixed;
    z-index: 24;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(140, 135, 128, 50%);
    opacity: 1;
    visibility: visible;
    transition: all .3s;
}

.userPages .menuBackdrop.hide{
    opacity: 0;
    visibility: hidden;
}

body:has(.userPages) .blockTitle{
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 20px;
}
body:has(.userPages) .blockDescription{
    color: #7d8080;
}

/*MODAL*/
body:has(.userPages) .modal-content{
    padding: 25px;
    background-color: var(--swatch--warm-white);

    .title{
        font-size: 22px;
        line-height: 24px;
    }
}
/*MODAL*/

@media (max-width: 991px) {
    .userPages .menuBackdrop{
        display: block;
    }
    .containerUserLayout{
        padding: 0 10px;
    }
    .titleProfileHead{
        font-size: 32px;
        line-height: 36px;
        max-width: 800px;
        margin: 0 auto 20px;
        padding: 0 10px;
    }
    .titleProfileSecond{
        font-size: 26px;
        line-height: 28px;
    }
    .userBlockInfo{
        padding: 15px 20px;
        box-shadow: none;
    }
    .userBlockInfoTitle{
        font-size: 16px;
        line-height: 20px;
    }
    .userPages .tabsList{
        margin-right: -10px;
        margin-left: -10px;
    }
}

@media (max-width: 572px) {
    body:has(.userPages) .blockTitle{
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 15px;
    }
}
