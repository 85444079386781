.formVcf{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 15px;
}

.imageContact{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: 0 auto;
}

.imageContact .wrapperBgHover{
    border-radius: 50%;
}

.inputVcf{
    width: 100%;
}

.dialogModalContact{
    max-width: 400px;
}

.buttonSaveVcf{
    width: 100%;
    max-width: 100%;
}
