.modalShare .modal-dialog{
    max-width: 450px;
}

.innerShareButtons{
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
}

.modalShareAdditionalText{
    font-size: 16px;
    line-height: 20px;
    white-space: pre-line;
}

.previewShareMessage{
    position: relative;
    border: 1px solid var(--mediun-gray);
    background-color: var(--light-gray);
    border-radius: 12px;
    margin-top: 10px;
    padding: 5px;
    white-space: pre-line;
}

.buttonCopyShareText{
    float: right;
    margin-left: 10px;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
}

.mobileShare{
    background-color: var(--lightest-gray);
    min-width: 45px;
    min-height: 45px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 572px) {
    .modalShare .modal-dialog{
        max-width: 100%;
    }
}
