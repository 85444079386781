.subscriptionStatusBadge{
    position: relative;
    display: flex;
    gap: 25px;
    align-items: center;
    background-color: #fbf9f9;
    border: 1px solid #e3dede;
    border-radius: 15px;
    padding: 15px;
}

.innerSubscriptionStatusIcon{
    padding: 15px;
    border: 1px solid #e3dede;
    background-color: #fefcfc;
    border-radius: 10px;
}

.subscriptionStatusBadge .innerInfo .total{
    font-size: 20px;
    line-height: 20px;
    margin-top: 4px;
}

.subscriptionStatusBadge .innerInfo .total span{
    font-weight: 700;
}

.subscriptionStatusBadge .innerInfo .additionalInfo{
    font-size: 12px;
    line-height: 12px;
}

.subscriptionStatusBadge .iconInfo{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: context-menu;
}
