.inner404{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title404{
    font-weight: 800;
    font-size: 20vw;
    line-height: 20vw;
    -webkit-text-stroke: 3px #040849;
    -webkit-text-fill-color: white;
}

.supTitleNotFound{
    font-size: 24px;
    line-height: 24px;
}

.buttonGoBack{
    margin-top: 30px;
    background-color: transparent;
    color: #040849;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px 70px;
    border: 2px solid #040849;
    border-radius: 14px;
    transition: all .5s;
}

.buttonGoBack:hover{
    color: #FFFFFF;
    background-color: #040849;
}

@media (max-width: 991px) {
    .title404{
        font-weight: 900;
    }

    .supTitleNotFound{
        margin-top: 10px;
        font-size: 20px;
        line-height: 22px;
    }
}
