.innerPreview{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex: 1;
    max-width: 478px;
    animation: showPreview .3s linear;
    animation-fill-mode: forwards;
    transition: all 1s;
}

@keyframes showPreview {
    0%{
        transform-origin: top;
        transform: scaleY(0);
    }
    100%{
        transform-origin: top;
        transform: scaleY(1);
    }
}

.previewMessage{
    position: relative;
    font-size: 16px;
    line-height: 17px;
    padding: 10px;
    margin-top: 10px;
    word-break: break-word;
    white-space: pre-line;
    border-radius: 10px;
    background-color: #e8e9ef;
}


.previewMessage:after{
    content: '';
    position: absolute;
    left: -8px;
    top: 5px;
    border: 5px solid transparent;
    border-top: 5px solid #e8e9ef;
    border-right: 5px solid #e8e9ef;
}
