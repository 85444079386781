.innerHomeContent{
    display: flex;
    align-items: flex-start;
    gap: 5%;
}

.innerHomeFeed{
    flex-grow: .75;
    width: 75%;
}

.innerHomeThemes{
    display: flex;
    flex-direction: column;
    min-height: 130px;
    flex-grow: .25;
    min-width: 250px;
    width: 25%;
}

.innerHomeThemes .imageEmptyBlock{
    max-width: 100px;
}

.innerHomeThemes .titleEmptyBlock{
    font-size: 16px;
    line-height: 16px;
}

.innerHomeThemes .viewMore{
    display: block;
    max-width: fit-content;
    margin-left: auto;
    margin-top: 15px;
    text-decoration: underline !important;
}

.innerHomeContent .subscriptionCard.sm .subscriptionPriceInfo,
.innerHomeContent .subscriptionCard.sm .subscriptionPaymentDateInfo{
    display: none;
}

@media (max-width: 1082px) {
    .innerHomeFeed .contentList > .contentArtist{
        display: none;
    }
}

@media (max-width: 991px) {
    .innerHomeContent{
        gap: 10px;
    }
    .innerHomeFeed{
        flex-grow: 1;
    }
    .innerHomeThemes{
        display: none;
    }
}
