.giftCard{
    overflow: hidden;
    border-radius: 1.5rem;
    border: 1px solid var(--swatch--beige);
}

.giftCardImage{
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}

.innerOrderInfo{
    padding: 2rem;
}

.innerPriceInfo{
    padding-bottom: 20px;
    border-bottom: 1px solid var(--swatch--beige);
    margin-bottom: 1.5rem;
}

.titlePrice{
    display: inline-block;
    font-size: 1.32rem;
    font-weight: 600;
}

.innerPrice{
    float: right;
}

.price{
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(22,171,161) !important;
    white-space: nowrap;
}

.discount{
    display: block;
    text-align: right;
    text-decoration: line-through;
}

.orderInfo > span{
    line-height: 30px;
    font-weight: 700;
}

@media (max-width: 991px) {
    .titlePrice{
        font-size: 1.2rem;
    }
    .orderInfo > span{
        line-height: 30px;
        font-weight: 700;
    }
}
