.dateRangePicker > .rs-picker-toggle{
    border: 2px solid var(--border-input) !important;
}

.dateRangePicker > .rs-picker-toggle-read-only{
    opacity: 1;
}

.dateRangePicker .rs-picker-toggle-textbox,
.dateRangePicker .rs-picker-toggle-value,
.dateRangePicker .rs-picker-toggle-textbox:focus,
.dateRangePicker .rs-picker-toggle-value:focus{
    color: var(--date-picker) !important;
}

.dateRangePicker .rs-picker-toggle:hover{
    border-color: var(--border-input) !important;
}

.dateRangePicker .rs-picker-toggle:focus-visible{
    outline-color: rgba(4, 8, 73, .3);
}

.innerFilterChart{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapperChart{
    max-width: 100%;
}

.innerEmptyDataLandingAnalytics{
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.textEmptyDataLandingAnalytics{
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
}

.innerLandingAnalyticChart{
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
}

.innerListUserPhone{
    position: absolute;
    left: -50px;
    top: 10px;
    bottom: 10px;
    height: fit-content;
    max-height: 100%;
    min-height: 200px;
    background: rgb(254 254 254);
    border: 2px solid hsla(0, 0%, 92%, .72);
    border-radius: 2px;
    -webkit-box-shadow: 0 0 15px 4px rgba(235, 235, 235, 0.82);
    padding: 5px 10px;
    -moz-box-shadow: 0 0 15px 4px rgba(235, 235, 235, 0.82);
    box-shadow: 0 0 15px 4px rgba(235, 235, 235, 0.82);
    overflow-y: scroll;
    z-index: 1;
}

.itemListUserPhone{
    border-bottom: 1px solid rgb(161 155 155);
    padding: 4px 0;
}

.viewMorePaginateLink{
    display: block;
    max-width: fit-content;
    margin-left: auto;
    margin-top: 5px;
    text-decoration: underline !important;
}

.switcherChartType{
    border-color: #f1f1f1;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.switcherChartType:hover{
    border-color: #dcd8d8 !important;
    border-top: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.innerDashboardCharts{
    min-height: 300px;
}

.innerLoaderDashboardChart{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.5);
}
.loading.dashboardChartLoading{
    height: 50px;
    width: 50px;
    margin: -40px 0 0;
}

.switcherChartType.activeChart{
    border-color: var(--catalina-blue) !important;
}
