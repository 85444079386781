.content{
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    line-height: 18px;
    border-bottom: 1px solid #eae7dc;
    padding: 10px 5px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .5s;
}

.content.textRow{
    padding-top: 14px;
    padding-bottom: 14px;
}

.collapsibleText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.collapsibleText.expanded:first-line {
    background-color: red;
    -webkit-line-clamp: unset; /* Remove line limit */
}

.collapsibleText.expanded {
    -webkit-line-clamp: unset; /* Remove line limit */
}

.content .message .toggleButton{
    background-color: transparent;
    padding: 0;
    color: var(--swatch--cta-blue);
    margin-left: auto;
}

.content:hover{
    background-color: #f9f0e4;
}

.content:last-child{
    border-bottom: none;
}

.contentImg{
    min-width: 35px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    object-fit: cover;
}

.contentTitle,
.content .message{
    font-weight: 500;
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    min-width: 130px;
    word-break: break-word;
    text-overflow: ellipsis;
}

.content .message{
    max-width: initial;

    & a{
        text-decoration: underline !important;
    }
}

.contentArtist{
    max-width: 200px;
    width: 100%;
    color: #969898;
    white-space: nowrap;
    overflow: hidden;
    min-width: 100px;
    text-overflow: ellipsis;
}

.contentArtist.mobile{
    display: none;
}

.contentDeliveryDate{
    flex: 1;
    text-align: right;
    color: #969898;
    white-space: nowrap;
    overflow: hidden;
    min-width: 110px;
    text-overflow: ellipsis;
}

.contentDeliveryDate.mobile{
    display: none;
}

@media (max-width: 991px) {
    .content{
        gap: 10px;
        font-size: 16px;
        line-height: 18px;
        padding: 10px 0;
    }
    .contentImg{
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 5px;
    }
    .contentTitle{
        max-width: none;
        flex: 1;
    }
    .contentArtist{
        display: none;
    }
    .contentArtist.mobile{
        display: block;
        font-size: 14px;
        line-height: 16px;
    }
    .contentDeliveryDate{
        font-size: 14px;
        line-height: 16px;
        max-width: fit-content;
    }
}

@media(max-width: 600px){
    .content.textRow{
        .contentDeliveryDate{
            display: none;

            &.mobile{
                display: block;
                min-width: auto;
            }
        }
    }
}
