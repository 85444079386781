.previewContent{
    position: relative;
    max-width: 390px;
    height: 100%;
    padding: 15px;


    .innerContentImg{
        position: absolute;
        inset: 15px 15px 0;
        z-index: 0;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: var(--border-radius);
        }
    }
    .block{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 1;
        margin-top: auto;
        min-height: 200px;
        bottom: 15px;
        left: 30px;
        right: 30px;
        padding: 10px 10px 20px;
        border-radius: var(--border-radius);
        -webkit-backdrop-filter: blur(35px) brightness(.9);
        backdrop-filter: blur(35px) brightness(.9);
        background: rgba(83, 77, 77, .45);
        color: #FFFFFF;

        .title{
            font-size: 20px;
            line-height: 20px;
            font-weight: 500;
            width: 100%;
            margin-bottom: 7px;
        }
        .body {
            width: 100%;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 270px;
            display: -webkit-box;
            -webkit-line-clamp: 15;
            -webkit-box-orient: vertical;
        }
    }
}
